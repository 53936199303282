import serviceAxios from "@/utils/request";
import { getMainStoreKeyId, getOwnerKeyId } from "@/storage/SessionParamers"
export const listCarousel = params => {
  // const { isEnable ,ownerKeyId} = params;
  return serviceAxios({
    url: `/shop-image-web/list-carousel-image?isEnable=0&&ownerKeyId=${getOwnerKeyId()}`
  });
};
export const listAnMouth = data => {
  return serviceAxios({
    url: "/goods-center/list-an-mouth",
    method: "post",
    body: {
      ...data
    }
  });
};

export const login = data => {
  const { phone, password } = data;
  return serviceAxios({
    url: `/login?username=${phone}&&password=${password}`,
    method: "post",
    // body: {
    //   // ...data
    // }
    useTokenAuthorization: "T"
  });
};

export const smsSend = params => {
  const { phone } = params;
  return serviceAxios({
    url: `/shop-user/sms-send/${phone}`,
    useTokenAuthorization: "T"
  });
};

export const shopUserRegister = data => {
  return serviceAxios({
    url: `/shop-user/register`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: "T"
  });
};

export const listShop = data => {
  return serviceAxios({
    url: `/goods-center/list`,
    method: "post",
    body: {
      ...data
    }
  });
};

export const selectDetail = params => {
  const { goodsKeyId, mainStoreKeyId } = params;
  return serviceAxios({
    url: `/car/select-detail?goodsKeyId=${goodsKeyId}&&mainStoreKeyId=${getMainStoreKeyId()}`,
    useTokenAuthorization: true
  });
};

export const getInventory = params => {
  const { produceKeyId, properties } = params;
  return serviceAxios({
    url: `/goods-center/get-inventory?mainStoreKeyId=${getMainStoreKeyId()}&&produceKeyId=${produceKeyId}&&properties=${properties}`
  });
};

export const getMemberInfo = params => {
  // const { produceKeyId, properties } = params;
  return serviceAxios({
    url: `/shop-userweb/get-member-info?mainStoreKeyId=${getMainStoreKeyId()}`,
    useTokenAuthorization: true
  });
};

export const listShopUser = data => {
  // const { produceKeyId, properties } = params;
  return serviceAxios({
    url: `/shop-user/list`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const selectRelevantGoods = data => {
  return serviceAxios({
    url: `/goods-center/select-relevant-goods`,
    method: "post",
    body: {
      ...data
    },
  });
};

export const addCar = data => {
  return serviceAxios({
    url: `/car/add`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const getDetail = params => {
  const { skuKeyId } = params;
  return serviceAxios({
    url: `/car/get-detail?mainStoreKeyId=${getMainStoreKeyId()}&&skuKeyId=${skuKeyId}`,
    useTokenAuthorization: true
  });
};

export const listCar = params => {
  // const { mainStoreKeyId } = params;
  return serviceAxios({
    url: `/car/list?mainStoreKeyId=${getMainStoreKeyId()}`,
    useTokenAuthorization: true
  });
};


export const resetPassword = data => {
  // const { mainStoreKeyId } = params;
  return serviceAxios({
    url: `/shop-user/reset-password`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: "T"
  });
};

export const listSpecProd = data => {
  return serviceAxios({
    url: `/goods-center/list-spec-prod`,
    method: "post",
    body: {
      ...data
    },
  });
};

export const listShopCate = params => {
  // const { mainStoreKeyId } = params;
  return serviceAxios({
    url: `/shop-cate/list?ownerKeyId=${getOwnerKeyId()}`,
    useTokenAuthorization: true
  });
};


export const carDelete = data => {
  return serviceAxios({
    url: `/car/deletecar`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const getDefaultDdress = params => {
  // const { mainStoreKeyId } = params;
  return serviceAxios({
    url: `ddress/get-default`,
    useTokenAuthorization: true
  });
};


export const getDdressList = params => {
  // const { mainStoreKeyId } = params;
  return serviceAxios({
    url: `ddress/list`,
    useTokenAuthorization: true
  });
};

export const addDdress = data => {
  return serviceAxios({
    url: `/ddress`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const setDefault = data => {
  return serviceAxios({
    url: `/ddress/set-default?addressKeyId=${data.addressKeyId}`,
    method: "put",
    useTokenAuthorization: true
  });
};



export const placeOrder = data => {
  return serviceAxios({
    url: `/car/place-order`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const changeQty = data => {
  return serviceAxios({
    url: `/car/changeQty`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const tradePrecreate = data => {
  return serviceAxios({
    url: `/ypgateway/trade_precreate`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const myOrders = data => {
  return serviceAxios({
    url: `/shop-userweb/my-order`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const myBought = data => {
  return serviceAxios({
    url: `/shop-userweb/my-boughtpage`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const orderDetail = data => {
  const { orderKeyId } = data;
  return serviceAxios({
    url: `/shop-userweb/order-detail?orderKeyId=${orderKeyId}`,
    method: "get",
    useTokenAuthorization: true
  });
};


export const deleteDdress = data => {
  return serviceAxios({
    url: `/ddress/delete`,
    method: "delete",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const revisePassword = data => {
  return serviceAxios({
    url: `/shop-userweb/revise-password`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};


export const payQrCode = data => {
  return serviceAxios({
    url: `/car/pay-qr-code`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const cancelOrder = data => {
  return serviceAxios({
    url: `/car/cancel-order`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const refreshPayStatus = data => {
  return serviceAxios({
    url: `/car/refresh-pay-status`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const listAboutUsImage = data => {
  return serviceAxios({
    url: `shop-image-web/list-about-us-image?ownerKeyId=${getOwnerKeyId()}`,
  });
};

export const listContactUsImage = data => {
  return serviceAxios({
    url: `shop-image-web/list-contact-us-image?ownerKeyId=${getOwnerKeyId()}`,
  });
};

export const imgsList = data => {
  const { produceKeyId } = data
  return serviceAxios({
    url: `produce-open-scan/imgs/list?produceKeyId=${produceKeyId}`,
    method: "get",
    useTokenAuthorization: true
  });
};

export const listParam = data => {
  const { skuKeyId } = data
  return serviceAxios({
    url: `goods-center/list-param?skuKeyId=${skuKeyId}`,
    method: "get",
    useTokenAuthorization: true
  });
};

export const cancelUnpayorder = data => {
  const { orderKeyId } = data
  return serviceAxios({
    url: `shop-userweb/cancel-unpayorder?orderKeyId=${orderKeyId}`,
    method: "post",
    useTokenAuthorization: true
  });
};

export const updateOrder = data => {
  return serviceAxios({
    url: `shop-userweb/update-order`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

// export const listRecmds = data => {
//   return serviceAxios({
//     url: `goods-publish/listRecmds`,
//     method: "post",
//     body: {
//       ...data
//     },
//     useTokenAuthorization: "T"
//   });
// };


export const listRecmds = data => {
  return serviceAxios({
    url: `goods-center/list-recmds`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: "T"
  });
};

export const goodsListDetail = data => {
  const { goodsKeyId } = data
  return serviceAxios({
    url: `goods-publish/list-detail?goodsKeyId=${goodsKeyId}`,
    method: "get",
    useTokenAuthorization: true
  });
};

export const wishlistedProducts = data => {
  return serviceAxios({
    url: `usergoods/add`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};

export const listUserGoods = data => {
  const { mainStoreKeyId } = data
  return serviceAxios({
    url: `usergoods/list?mainStoreKeyId=${mainStoreKeyId}`,
    method: "get",
    useTokenAuthorization: true
  });
};


export const deleteUserGoods = data => {
  return serviceAxios({
    url: `usergoods/deletecar`,
    method: "post",
    body: {
      ...data
    },
    useTokenAuthorization: true
  });
};



import React, { Component } from "react";
import "./index.css";
import { listCar, carDelete, changeQty } from "@/utils/user";
import { Tabs } from 'antd';
import MyOrders from '@/componentsMobile/PersonalCentre/MyOrders';
import PurchasedGoods from '@/componentsMobile/PersonalCentre/PurchasedGoods';
import WishlistedItems from '@/componentsMobile/PersonalCentre/WishlistedItems';
import AddressController from '@/componentsMobile/PersonalCentre/AddressController';
import AccountInfo from '@/componentsMobile/PersonalCentre/AccountInfo';
import { connect } from "react-redux";
import { cartNumber, cartNumberDelect } from "@/actions";
import { UserOutlined } from '@ant-design/icons';
import MyContext from '@/componentsMobile/MyContext';
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

let pageContext;
class PersonalCentre extends Component {
  constructor(props) {
    super(props);
    loadLocales('PersonalCentre');
    this.state = {
      tabsState: "1",
      uesrname: false
    };
    pageContext = this;
  }

  componentDidMount() {
    this.listCars();
  }

  listCars() {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList) {
          let shopCarNum = res.data.shopCarVOList.length;
          this.props.cartNumber(shopCarNum);
        } else {
          this.props.cartNumber(0);
        }
        this.setState({
          shopCarVOList: res.data.shopCarVOList,
          listCars: res.data
        });
      }
    });
  }

  onChange = (key) => {
    this.setState({ tabsState: key });
  };

  userNameFun(uesrname) {
    if (uesrname) {
      pageContext.setState({ uesrname });
    }
  }


  render() {
    const { listCars, symbol, shopCarVOList, tabsState, uesrname } = this.state;
    const items = [
      { key: "1", label: <div className="tabsItem1">{intl.get("MyOrders")}</div> },
      { key: "2", label: <div className="tabsItem1">{intl.get("PurchasedItems")}</div> },
      { key: "3", label: <div className="tabsItem1">{intl.get("WishlistedItems")}</div> },
      { key: "4", label: <div className="tabsItem1">{intl.get("deliveryAddress")}</div> },
      { key: "5", label: <div className="tabsItem1">{intl.get("AccountInformation")}</div> }
    ];
    const isMobile = window.innerWidth < 768;

    let tabsPage = "";
    switch (tabsState) {
      case "1":
        tabsPage = <MyOrders history={this.props.history} userNameFun={this.userNameFun} />;
        break;
      case "2":
        tabsPage = <PurchasedGoods history={this.props.history} />;
        break;
      case "3":
        tabsPage = <WishlistedItems history={this.props.history} />;
        break;
      case "4":
        tabsPage = <AddressController history={this.props.history} />;
        break;
      case "5":
        tabsPage = <AccountInfo history={this.props.history} />;
        break;
    }

    return (
      <div>

        <section>
          <div className="container">
            <Tabs
              defaultActiveKey="1"
              onTabClick={this.onChange}
              items={isMobile ? items.map(item => ({ ...item, label: item.label })) : items} // 移动端显示简写
              type={isMobile ? "card" : "line"}
            />
            <div>
              {tabsPage}
            </div>

          </div>
          <MyContext.Provider value={{ name: "chenya", age: 18 }}></MyContext.Provider>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.products.find(el => el.id == productId),
    symbol: state.data.symbol
  };
};

export default connect(mapStateToProps, { cartNumber, cartNumberDelect })(PersonalCentre);


import React, { useRef, useEffect, useState } from 'react';
import {
  Modal, Row, Col, Spin, Table, Grid
} from "antd";
import intl from 'react-intl-universal';
import './details-price.css'
const { useBreakpoint } = Grid;
const errorPhoto = require('@/images/errorPhoto.png')
export default function AddingProductsExistingOrderModal(props) {
  const [refreshState, setRefreshState] = useState(true)
  const [fulfil, setFulfil] = useState(false)
  const scrollDivRef = useRef(null);

  const screens = useBreakpoint();
  const isMobile = !screens.md;


  // 手机端优化滚动容器高度
  const scrollContainerStyle = {
    height: isMobile ? '45vh' : '60vh',
    overflowY: 'auto'
  };


  const handleScroll = () => {
    const { current } = scrollDivRef;
    if (current.scrollHeight - current.scrollTop === current.clientHeight) {
      if (props.myOrdersPagination.size >= props.myOrdersPagination.total) {
        setFulfil(true)
      }
      if (refreshState && props.myOrdersPagination.size < props.myOrdersPagination.total) {
        setRefreshState(false)
        props.loaderOrderList((res) => { setRefreshState(true) })
      }

      // 在这里调用你的函数或者设置状态
    }
  };

  useEffect(() => {
    const { current } = scrollDivRef;
    if (current) {
      current.addEventListener('scroll', handleScroll);
      return () => current.removeEventListener('scroll', handleScroll);
    }

  }, [refreshState]);
  const columns = [
    {
      title: intl.get("sequence"),
      key: 'sequence',
      render: (_, __, index) => index + 1,
      responsive: ['md'],
      width: 60
    },
    {
      title: intl.get("productImage"),
      dataIndex: 'mainPicture',
      key: 'mainPicture',
      width: 100,
      render: (item) => (
        <img
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = errorPhoto
          }}
          className='img_sty'
          src={item}
          alt=""
          style={{
            width: isMobile ? '50px' : '70px',
            height: isMobile ? '50px' : '70px',
            objectFit: 'contain'
          }}
        />
      )
    },
    {
      title: intl.get("productName"),
      dataIndex: 'produceName',
      key: 'produceName',
      ellipsis: true,
      width: 230
    },
    {
      title: (
        <span className="compact-header">
          {intl.get("quantity")}
        </span>
      ),
      dataIndex: 'orderQty',
      key: 'orderQty',
      width: 100,
      className: 'no-wrap-column',
      render: text => <span className="compact-cell">{text}</span>
    },
  ];
  return (
    <Modal
      title={intl.get("AddtoExistingOrder")}
      open={props.open}
      center={"true"}
      onCancel={props.onCloseAddProdModal}
      width={'99%'}
      centered="true"
      footer={null}

    >
      <div >
        <Row gutter={0}>
          <Col xs={24}
            md={24}
            className="prodmodel_myOrdersList"
            style={scrollContainerStyle}
            ref={scrollDivRef}>

            {!props.myOrdersList ? "" : props.myOrdersList.map((item, index) =>
              <div
                onClick={(e) => { props.onCheckbox(index); props.getOrderDetail(item) }}
                key={item.keyId}
                className={props.useAddress == index ? "prod_radio_frame mobile-order-item" : "prod_radio_frame_none mobile-order-item"} >
                <div className='font-size-20 prodmodel_order_info'>
                  <div style={{ flex: 1 }}>
                    <div className='text_grey mobile-text'>{item.createDateFortmat}</div>
                    <div className='prodmodel_order_info_prodQty'>
                      <span className='text_grey_85 fontSize-16 mobile-text'>{intl.get("NumberItems")}</span>
                      <span className='text_grey mobile-text'>{item.prodQty}</span>
                    </div>
                  </div>
                  <div>
                    <div className='text_grey_85 mobile-text'>{item.orderNo}</div>
                    <div className='blue_text mobile-price'>{"$ " + item.amtValue.toFixed(2)}</div>
                  </div>

                </div>
              </div>
            )}
            <Spin className='prodmodel_spin' spinning={!refreshState} />
            {fulfil ? <div className='prodmodel_spin' >已經到底啦!</div> : ""}
          </Col>
          <Col
            xs={24}
            md={24}
            style={{ marginTop: '5px' }}
          >
            <Table
              loading={props.orderDetailLoading}
              columns={columns}
              dataSource={props.orderDetailList}
              pagination={false}
              scroll={{ x: true }} // 手机端允许横向滚动
              size={isMobile ? 'small' : 'middle'} // 响应式表格尺寸
              bordered={isMobile}
            />
          </Col>

        </Row>

      </div>
      <div className='prodmodel_button'>
        <div onClick={() => props.addProdmodel()} className='blue_border_text mobile-button'>確定加入</div>
      </div>

    </Modal >
  )
}


import React, { Component } from 'react'
import { Form, Button, Input, message } from 'antd';
import { getMemberInfo, revisePassword } from '@/utils/user'
import { toast } from "react-toastify";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { removeToken, removeUserName } from '@/storage/LocalParamers.js';
export default class AccountInfo extends Component {
  constructor(props) {
    super(props);
    loadLocales('PersonalCentre')
    this.state = {
      memberInfo: false
    }
    this.accountInfoFormRef = React.createRef();
    this.changePasswordFormRef = React.createRef();
  }
  componentDidMount() {
    this.getMemberInfo()
  }

  getMemberInfo() {
    getMemberInfo().then(res => {
      this.setState({ memberInfo: res.data });
    })
  }

  onClLogOut() {
    removeUserName();
    removeToken();
    this.props.history.push("/pages/login");
  }

  onFinishChangeNickname = () => {
    const { memberInfo } = this.state
    this.accountInfoFormRef.current.validateFields().then(value => {
      if (value.nkName == memberInfo.nkName || !value.nkName || value.nkName == "") {
        message.open({
          type: 'warning',
          content: intl.get("NicknameNotModifiedOrEmpty"),
          style: {
            marginTop: '20vh',
          },
        });
        return
      } else {
        const params = {
          nickName: value.nkName,
          oldPwd: null,
          // userKeyId: getToken(),
          newPwd: null,
          userName: memberInfo.userName
        }
        revisePassword(params).then(res => {
          if (res && res.responseCode === "00") {
            toast.success(intl.get("ModifiedSuccessfully"));
            this.getMemberInfo()
          } else {
            toast.success(res.responseMessage);
          }
        })
      }
    })
  }

  onFinish = () => {
    const { memberInfo } = this.state
    this.changePasswordFormRef.current.validateFields().then(value => {
      const params = {
        nickName: null,
        oldPwd: value.oldPwd,
        // userKeyId: getToken(),
        newPwd: value.newPwd,
        userName: memberInfo.userName
      }
      revisePassword(params).then(res => {
        if (res && res.responseCode === "00") {
          toast.success(intl.get("ModifiedSuccessfully"));
          this.getMemberInfo()
        } else {
          toast.success(res.responseMessage);
        }
      })
    })
  }

  render() {
    const { memberInfo } = this.state
    const fields = [
      {
        name: ['nkName'],
        value: memberInfo.nkName,
      },
    ]
    return (
      <div className='personalCentre_accountInfo_sty'>
        <div>
          <div>{intl.get("AccountBasicInformation")}</div>
          <div className="personal_logOut1">
            <div className="font-color-blue" onClick={() => this.onClLogOut()} style={{
              textAlign: "center"
            }}
            >
              {intl.get("LogOut")}
            </div>
          </div>
          <Form
            ref={this.accountInfoFormRef}
            onFinish={this.onFinishChangeNickname}
            onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            layout="vertical"
            fields={fields}
          >
            <Form.Item
              label={intl.get("nicknames")}
              name="nkName"
              rules={[
                {
                  required: true,
                  message: intl.get("enterNickname"),
                },
              ]}
            >
              <Input size="large" placeholder={intl.get("PleaseEnter")} />
            </Form.Item>
            <Form.Item
              label={intl.get("phone")}
            >
              <div className='font-size-20' style={{ marginLeft: "10px" }}>{memberInfo.areaCode + memberInfo.phoneCode}</div>
            </Form.Item>

            <Button size="large" style={{ float: "right" }} type="primary" ghost htmlType="submit">
              {intl.get("ModifyNickname")}
            </Button>
          </Form>
        </div>
        <div>
          <div>{intl.get("ChangeLoginPassword")}</div>
          <Form
            ref={this.changePasswordFormRef}
            onFinish={this.onFinish}
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label={intl.get("OriginalPassword")}
              name="oldPwd"
              rules={[
                {
                  required: true,
                  message: intl.get("PleaseEnterOriginalPassword"),
                },
              ]}
            >
              <Input.Password size="large" placeholder={intl.get("PleaseEnter")} />
            </Form.Item>
            <Form.Item
              label={intl.get("NewPassword")}
              name="newPwd"
              dependencies={['oldPwd']}
              rules={[
                {
                  required: true,
                  message: intl.get("PleaseEnterNewPassword"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('oldPwd') === value) {
                      return Promise.reject(new Error(intl.get("OriginalPasswordAndNewPasswordCannotBeTheSame")));
                    } else {
                      return Promise.resolve();

                    }
                  },
                }),
              ]}
            >
              <Input.Password size="large" placeholder={intl.get("PleaseEnter")} />
            </Form.Item>

            <Form.Item
              label={intl.get("ConfirmPassword")}
              name="confirmPwd"
              dependencies={['newPwd']}
              rules={[
                {
                  required: true,
                  message: intl.get("PleaseConfirmPassword"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPwd') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(intl.get("NewPasswordAndConfirmedPasswordNeedBeSame")));
                  },
                }),
              ]}
            >
              <Input.Password size="large" placeholder={intl.get("PleaseEnter")} />
            </Form.Item>

            <Button size="large" style={{ float: "right" }} type="primary" ghost htmlType="submit">
              {intl.get("ChangePassword")}
            </Button>
          </Form>
        </div>
      </div>
    )
  }
}

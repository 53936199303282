import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../common/breadcrumb";
import { getCartTotal } from "../../services";
import { listCar, carDelete, changeQty } from "@/utils/user";
import { Table, message, Image, Popconfirm, Button } from 'antd';
import { CloseOutlined, InfoCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { getToken, getCurCode } from "@/storage/LocalParamers";
import { getOwnerKeyId, getMainStoreKeyId, putShoppingCartSelected } from "@/storage/SessionParamers";
import ProductRecommendation from '@/components/register/productRecommendation.js'
import { connect } from "react-redux";
import { cartNumber, purchaseNow, addToWishlist } from "@/actions";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { toast } from "react-toastify";

const errorPhoto = require('@/images/errorPhoto.png')
let pageContext
class cartComponent extends Component {
  constructor(props) {
    super(props);
    loadLocales('cart')
    this.state = {
      shopCarVOList: [],
      symbol: getCurCode(),
      listCars: [],
      paidGoods: new Object,
      nowQty: false,
      specialOfferTotal: "0.00",
      regularTotal: "0.00",
      selectedRowKeys: new Array()
    };
    pageContext = this
    this.handleDeleteCar = this.handleDeleteCar.bind(this)
  }
  componentDidMount() {

    this.listCars();
  }

  listCars = () => {
    const { selectedRowKeys } = this.state
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList) {
          if (selectedRowKeys.length > 0) {
            let b = res.data.shopCarVOList;
            let selected = [];
            for (const i of b) {
              for (const item of selectedRowKeys) {
                if (i.keyId == item) {
                  selected.push(i)
                }
              }
            }
            this.onStatisticalOrdersInfo(selected)
          }

          let a = res.data.shopCarVOList
          a.forEach(i => i.key = i.keyId)
          this.setState({
            shopCarVOList: a,
          });
        } else {
          this.props.cartNumber(0)
        }
        this.setState({
          listCars: res.data
        });
      }
    });
  }

  handleDeleteCar = () => {
    if (pageContext.state.shopCarVOList && pageContext.state.shopCarVOList.length > 0) {
      const deleteList = pageContext.state.shopCarVOList.map(item => item.keyId)
      const param = { keyIdList: deleteList }
      carDelete(param).then(res => {
        if (res && res.responseCode == "00") {
          this.props.cartNumber(0)
          this.setState({
            shopCarVOList: []
          });

        }
      });
    }

  }

  decrementQty = (item) => {
    if (item) {
      const param = {
        keyId: item.keyId,
        loginUserKeyId: item.loginUserKeyId,
        ownerKeyId: getOwnerKeyId(),
        quantity: item.quantity - 1,
        relationGoodKeyId: item.relationGoodKeyId,
        rowVersion: "1",
        skuKeyId: item.skuKeyId,
        outMainStoreKeyId: getMainStoreKeyId(),
      }
      this.onCheckChangeQty(param)
    }
  }

  incrementQty = (item, index) => {
    if (item) {
      const param = {
        keyId: item.keyId,
        loginUserKeyId: item.loginUserKeyId,
        ownerKeyId: getOwnerKeyId(),
        quantity: item.quantity + 1,
        relationGoodKeyId: item.relationGoodKeyId,
        rowVersion: "1",
        skuKeyId: item.skuKeyId,
        outMainStoreKeyId: getMainStoreKeyId(),
      }
      this.onCheckChangeQty(param)
    }
  }

  inputQty = (item) => {
    const { nowQty } = this.state
    if (item && nowQty !== item.quantity) {
      const param = {
        keyId: item.keyId,
        loginUserKeyId: item.loginUserKeyId,
        ownerKeyId: getOwnerKeyId(),
        quantity: item.quantity === "" ? 0 : item.quantity,
        relationGoodKeyId: item.relationGoodKeyId,
        rowVersion: "1",
        skuKeyId: item.skuKeyId,
        outMainStoreKeyId: getMainStoreKeyId(),
      }
      this.onCheckChangeQty(param)
    }
  }

  onFucsInputQty = (item) => {
    this.setState({ nowQty: item })

  }
  onCheckChangeQty = (param) => {
    changeQty(param).then(res => {
      if (res && res.responseCode == "00") {
        //toast.success(res.responseMessage);
        // if (param.quantity) {
        //   this.props.cartNumber(param.quantity);

        // }
        this.listCars();
      }
    })
  }

  removeFromCart = (val) => {
    let shopCarList = [val.keyId]
    // for (const item of pageContext.state.shopCarVOList) {
    //   if (item.keyId == val.keyId) {
    //     shopCarList.push(new pageContext.reconfigurationStructure(item))
    //   }
    // }

    const param = { keyIdList: shopCarList }
    carDelete(param).then(res => {
      if (res && res.responseCode == "00") {
        this.listCars()
      }
    });
  }

  onChInput = (value, index) => {
    const {
      shopCarVOList
    } = this.state;
    let list = shopCarVOList;
    if (value >= 0) {
      list[index].quantity = parseFloat(value);
      this.setState({ shopCarVOList: list });
    }
  }

  onSelectChange = (record, selected) => {
    this.onStatisticalOrdersInfo(selected)
    this.setState({ selectedRowKeys: record });
  };

  onStatisticalOrdersInfo = (selected) => {
    const { paidGoods, listCars } = this.state
    let newPaidGoods = {
      prodQty: 0,
      totalDiscountAmount: 0,
      normalAmt: 0,
      normalDiscAmt: 0,
      amtValue: 0,
      totalOriginalAmount: 0
    };
    if (selected.length > 0) {

      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];

        newPaidGoods.prodQty += parseFloat(selected[index].quantity)
        newPaidGoods.totalOriginalAmount += parseFloat(selected[index].producePrice) * parseFloat(selected[index].quantity)
        if (selected[index].specialStatus !== 0 && selected[index].specialPrice != 0) {
          newPaidGoods.totalDiscountAmount += parseFloat(selected[index].quantity) * parseFloat(selected[index].specialPrice)
        } else {
          newPaidGoods.normalAmt += (parseFloat(selected[index].quantity) * parseFloat(selected[index].producePrice))
        }
      }

      newPaidGoods.normalDiscAmt = listCars.clientDisc > 0 ? newPaidGoods.normalAmt * parseFloat(listCars.clientDisc) * 0.01 : "0"
      newPaidGoods.amtValue = newPaidGoods.normalAmt + newPaidGoods.totalDiscountAmount - newPaidGoods.normalDiscAmt
    }
    newPaidGoods.selected = selected
    this.setState({ paidGoods: newPaidGoods })
  }


  onPushSubmit = () => {
    const { listCars, paidGoods } = this.state
    if (!paidGoods.prodQty || paidGoods.prodQty < 1) {
      toast.info(intl.get("UncheckedItemsInTheShoppingCart"))
      return
    }
    paidGoods.clientDisc = listCars && listCars.clientDisc && parseFloat(listCars.clientDisc) >= 0 ? listCars.clientDisc : "0"
    putShoppingCartSelected(paidGoods)
    this.props.history.push(`${process.env.PUBLIC_URL}/checkout`)
  }

  render() {

    const { listCars, symbol,
      shopCarVOList, selectedRowKeys, paidGoods
    } = this.state;

    const columns = [
      {
        title: intl.get("sequence"),
        dataIndex: 'serials',
        key: 'serials',
        width: 70,
        render: (text, record, index) => <div>{index + 1}</div>,
        align: "center"
      },
      {
        title: intl.get("productImage"),
        key: 'pictures',
        width: 100,
        align: "center",
        render: (item) =>
          <Image
            className="cart_pictures"
            src={!item && item.mainPicture ? "" : item.mainPicture}
            alt=""
            fallback={errorPhoto}
          // onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
          />
      },
      {
        title: intl.get("productName"),
        key: 'name',
        align: "left",
        width: 180,
        render: item =>
          <div
          // onClick={() => this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/product/${item.keyId}`)}
          >
            {item.produceName}
          </div>
      },
      {
        title: intl.get("price"),
        key: 'price',
        width: 120,
        align: "right",
        render: item =>
          <div>
            <div
              className="specialPrice"
              style={{ display: item.specialStatus == 0 ? "none" : "" }}>
              {symbol} {item.specialPrice}
              <div>{intl.get("specialOffers")}</div>
            </div>
            <h2 style={{ display: item.specialStatus == 0 ? "" : "none" }}>
              {symbol} {item.producePrice}
            </h2>
          </div >
      },
      {
        title: intl.get("quantity"),
        key: 'quantity',
        align: "center",
        width: 180,
        render: (text, record, index) =>
          <div className="qty-box">
            <div className="qty-box">
              <div className="inputGroup">
                <div className="qty-select">
                  <button
                    type="button"
                    className="btn quantity-left-minus"
                    onClick={() =>
                      this.decrementQty(text)
                    }
                    data-type="minus"
                    data-field=""
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                </div>
                <div className='inputNumber'>
                  <input
                    type="number"
                    name="quantity"
                    value={text.quantity}
                    onInput={(e) => this.onChInput(e.target.value, index)}
                    onFocus={() => this.onFucsInputQty(text.quantity)}
                    onBlur={() => this.inputQty(text)}
                    className="input-number-style"
                  />
                </div>
                <div className="qty-select">
                  <button
                    className="btn quantity-right-plus"
                    onClick={() => {
                      this.incrementQty(text, index)
                    }
                    }
                    data-type="plus"
                    disabled={
                      text.qty >= text.stock ? true : false
                    }
                  >
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
            {text.qty >= text.stock ? "out of Stock" : ""}
          </div >
      },
      {
        title: intl.get("total"),
        key: 'total',
        width: 170,
        align: "right",
        render: item =>
          <div>
            <div
              style={{ display: item.specialStatus == 0 ? "none" : "" }}
              className={item.specialStatus == 0 ? "" : "specialPrice"}>

              {symbol} {(item.specialPrice * item.quantity).toFixed(1)}
            </div>
            <h2
              style={{ display: item.specialStatus == 0 ? "" : "none" }}
            >
              {symbol} {(item.producePrice * item.quantity).toFixed(1)}
            </h2>
          </div >
      },
      {
        title: intl.get("operation"),
        key: 'operation',
        width: 80,
        align: "center",
        render: item =>
          <Popconfirm
            title={intl.get('deleteItem')}
            description={intl.get('removeThisShoppingCart')}
            onConfirm={() => this.removeFromCart(item)}
          >
            <Button type="text">
              <CloseOutlined style={{ color: "#EE1D23" }} />
            </Button>
          </Popconfirm>
      },
    ];
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        {/*SEO Support*/}
        {/*SEO Support End */}
        {shopCarVOList.length > 0 ? (
          <section >
            <div className="containermb1">
              <div>
                <Table
                  rowSelection={rowSelection}
                  scroll={{
                    x: 1010, y: "100%"
                  }} columns={columns} dataSource={shopCarVOList} pagination={false}
                />
              </div>


              <div className="flex-row">
                <div className="‌friendly-reminder">
                  <div>
                    {intl.get("‌FriendlyReminder")}

                  </div>
                  {intl.get("‌FriendlyReminderContext")}
                </div>
              </div>
              <div className="amount_statistics_sty">
                <div>
                  <div>{intl.get("NumberItems")}<span className="font_color_grey">{paidGoods.prodQty ? paidGoods.prodQty : "0"}</span></div>
                  <div>{intl.get("specialOfferTotal")}
                    <span className="font_color_red">
                      <span>{symbol}</span>{paidGoods && paidGoods.totalDiscountAmount && parseFloat(paidGoods.totalDiscountAmount) != "NaN" ? parseFloat(paidGoods.totalDiscountAmount).toFixed(1) : "0.00"}</span></div>
                  <div>{intl.get("regularTotal")}
                    <span className="font_color_grey"><span>{symbol}</span>{paidGoods && paidGoods.normalAmt && parseFloat(paidGoods.normalAmt) != "NaN" ? parseFloat(paidGoods.normalAmt).toFixed(1) : "0.00"}</span></div>
                </div>
                <div>
                  <div>
                    {intl.get("regularItemDiscount")}(-{listCars && listCars.clientDisc && parseFloat(listCars.clientDisc) >= 0 ? listCars.clientDisc : "0"}%)
                    <span className="offer_amount">
                      <span>{symbol}</span>
                      {paidGoods && paidGoods.normalDiscAmt && paidGoods.normalDiscAmt >= 0 && parseFloat(paidGoods.normalDiscAmt) != "NaN" ? parseFloat(paidGoods.normalDiscAmt).toFixed(1) : "0.00"}</span>
                  </div>
                </div>
                <div>
                  <div>
                    {intl.get("orderAmount")}
                    <span className="font_color_blue">
                      <span>{symbol}</span>{paidGoods && paidGoods.amtValue && parseFloat(paidGoods.amtValue) != "NaN" ? parseFloat(paidGoods.amtValue).toFixed(1) : "0.00"}
                    </span>
                  </div>
                </div>

                <div className="operation_sty">
                  <div className="btn btn-solid" onClick={this.handleDeleteCar}>
                    {intl.get("emptyCart")}
                  </div>
                  <div>
                    <Link
                      to={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                      className="btn btn-solid keep_shopping_sty"
                    >
                      {intl.get("continueShopping")}
                    </Link>
                  </div>
                  <div
                    className="btn btn-solid"
                    onClick={() => {
                      if (shopCarVOList.length > 0) {
                        this.onPushSubmit()

                      } else {
                        message.warning(intl.get("shoppingCartIsEmpty"))
                      }
                    }}
                  >{intl.get("placeOrder")}
                  </div>
                </div>
              </div>

            </div>
          </section>
        ) : (
          <div>
            <div className="containermb1">
              <div className='registration_successful'>
                <div className='registration_successful_scope'>
                  <div className='registration_successful_icon'>
                    <InfoCircleOutlined />
                    <div>{intl.get("noItemsInCart")}</div>
                  </div>
                  <div className='registration_successful_tips'>
                    {intl.get("addItemsToCartFirst")}
                  </div>
                  <div className='order_success_btn'>
                    <div className="registration_successful_keepShopping"
                      onClick={() => this.props.history.push("/")}>
                      {intl.get("returnToHomepage")}
                    </div>
                    <div
                      className="registration_successful_viewOrder"
                      onClick={() => this.props.history.push("/no-sidebar/collection")}>
                      {intl.get('productCenter')}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            {/*Regsiter section*/}
            <section className="register-page section-b-space">
              <div className="container container1">
                <div className="row">
                </div>
              </div >
            </section >
          </div >

        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {

};

export default connect(mapStateToProps, { cartNumber })(cartComponent);

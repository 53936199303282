import React, { Component } from "react";
import { Link } from "react-router-dom";

import { SlideUpDown } from "../../../services/script";
import LogoImage from "../headers/common/logo";
import "./footer-one.css";
import "@/components/app.scss"
class FooterOne extends Component {
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function (elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  render() {
    const newDate = new Date().getFullYear();
    return (
      <div className="mobile-fix-option" style={{ height: '60px' }}>
        <footer className="responsive-footer" style={{ margin: '0px', padding: '5px' }} >
          <div className="footer-content" >
            <p className="copyright-info" style={{ margin: '0px', padding: '0px' }}>
              Copyright Hands Information Technology (Zhuhai) Ltd. ® {newDate}. All Rights Reserved.
            </p>
            <p className="company-name" style={{ margin: '0px', padding: '0px' }}>
              帮手信息技术（珠海）有限公司
            </p>

          </div>
        </footer>
      </div>
    );
  }
}

export default FooterOne;

import React, { Component } from 'react'
import { toast } from 'react-toastify';
import { putSelectGoods } from '@/storage/SessionParamers';
import '../no-sidebar.css';
import {
  getToken

} from "@/storage/LocalParamers";
const errorPhoto = require('@/images/errorPhoto.png')
export default class RelatedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: "853",
      page: 1,
      productList: [],
      totalPages: 1,
      list: []
    };
  }

  componentDidMount() {
    if (this.props.recommendation !== undefined) {
      this.props.recommendation(this);
    }

  }

  relatedProductsInfo = (list) => {
    const sidebarPage = 5;
    let productList = []
    let page = 1
    let nub = 0
    for (const key in list) {
      if (nub < (sidebarPage * page)) {
        productList.push(key)
        nub++
      }
    }
    this.setState({ productList, totalPages: Math.ceil(list.length / 3), list });
  }
  onGoodsJump(item) {
    let locationHref = window.location.href;
    if (!locationHref.includes(item.keyId)) {
      putSelectGoods(item.goodsCode)
      this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/product/${item.keyId}`)
    }
  }

  render() {
    const { productList, list } = this.state;
    return (
      <div className='related_products_list'>
        {list.map((item, index) =>
          productList.indexOf(index.toString()) != "-1" ?
            <div onClick={() => this.onGoodsJump(item)} key={index} className='related_products_info'>
              <div className='related_products_img'>
                <img onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }} src={item.mainPicture} alt='' />
              </div>
              <div className='related_products_name'>
                <div className='goodsName_style1'>{item.goodsName}</div>
                <div className='goodsName_style1'>{!getToken() ? "" : "$" + item.price}</div>
                <div></div>
              </div>
            </div> : ""
        )}

      </div>
    )
  }
}

import React, { Component } from "react";
import "./login.css";
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
import { login, getMemberInfo } from "@/utils/user";
import PropTypes from "prop-types";
import { putToken, putCurCode, putUserName } from "@/storage/LocalParamers";
import { toast } from "react-toastify";
import { Select, Input } from 'antd'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { getJumpLoginPage } from '@/storage/SessionParamers'
let pageContext
class Login extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    loadLocales('pages')
    this.state = {
      countryCode: "853"
    };
    pageContext = this
  }
  componentDidMount() {
  }
  login = () => {
    const { countryCode, phone, password } = this.state;
    let params = {
      countryCode: countryCode,
      phone: phone,
      password: password
    };
    login(params).then(res => {
      if (res && res.data && res.responseCode == "00") {
        toast.success(res.responseMessage, 4000);

        putToken(res.data.token);
        getMemberInfo().then(res2 => {
          if (res2.data) {
            putUserName(res2.data.nkName)
            putCurCode(res2.data.curCode);
            this.props.history.push(`${process.env.PUBLIC_URL}/`);

          }


        });
      }
    });
  }

  onSelectCountryCode(value) {
    // let value = e.target.value;
    pageContext.setState({ countryCode: value });
  }
  onInputChange(e) {
    let value = e.target.value;
    pageContext.setState({ phone: value });
  }

  onInputPhoneChange(e) {
    pageContext.setState({ phone: e.target.value });
  }

  onInputPasswordChange(e) {
    pageContext.setState({ password: e.target.value });
  }

  rememberMe(e) {
    pageContext.setState({ rememberMe: e.target.checked })
  }
  render() {
    return (
      <div >
        {/*Login section*/}
        <section className="login-page">
          <div className="container container2">
            <div className="row">
              <div className="col-lg-6">
                <div className="login_title_sty">{intl.get("SignIn")}</div>
                <div className="login_sty">
                  <form className="theme-form" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="login_btn_container">
                      <label htmlFor="email">{intl.get("phone")}</label>
                      <div className="region_select">
                        <div className="region">
                          <Select
                            defaultValue="853"
                            bordered={false}
                            style={{ width: "120px" }}
                            onChange={this.onSelectCountryCode}
                            options={[
                              { value: '853', label: intl.get("macao") },
                              { value: '852', label: intl.get("hongkong") },
                              { value: '86', label: intl.get("china") },
                            ]}
                          />
                        </div>
                        <Input
                          type="text"
                          style={{ '--ant-input-active-border-color': '#2e3092' }}
                          className="phone_inputs"
                          id="phone"
                          placeholder={intl.get("phoneNumber")}
                          required=""
                          onChange={this.onInputPhoneChange}
                        />
                      </div>
                    </div>
                    <div className="login_form_group">
                      <div className="login_form_group_title">
                        <div>{intl.get("cryptographic")}</div>
                        <a onClick={() => this.props.history.push("/pages/forgotten-password")} className="forgotten_password">{intl.get("forgottenPassword")}?</a>
                      </div>
                      <div>
                        <Input.Password
                          style={{ '--ant-input-active-border-color': '#2e3092' }}
                          type="password"
                          className="password_inputs"
                          id="review"
                          placeholder={intl.get("EnterLoginPassword")}
                          required=""
                          onChange={this.onInputPasswordChange}
                          onKeyPress={(e) => {
                            if (e.nativeEvent.keyCode === 13) { //判断是否是点击的回车
                              this.login()
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div
                      // href="#"
                      className="login_btn"
                      onClick={() => this.login()}
                    >
                      {intl.get("SignIn")}
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6" style={{ height: "100%" }}>
                <div className="login_title_sty">{intl.get("newUser")}</div>
                <div className="login_sty">
                  <p>
                    {intl.get("PleaseRegisterBeforeLoggingIn")}
                  </p>
                  <Link to={`/pages/register`}>
                    <div className="login_btn"> {intl.get("SignUpNow")}</div>
                  </Link>
                  <div className="push"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Login;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { listShop } from "@/utils/user";
import { getTotal, getCartProducts } from "../../../reducers";
import { addToCart, addToWishlist, addToCompare } from "../../../actions";
import { getVisibleproducts } from "../../../services";
import ProductListItem from "./product-list-item";
import { putProductInfo } from "@/storage/PageParamers";
import {
  Pagination, Tree, Row, Col, Spin, Popover
} from 'antd';
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { getMainStoreKeyId } from "@/storage/SessionParamers";
import '../collection-no-sidebar.css';
let pageContext
const errorPhoto = require('@/images/errorPhoto.png')
// @connect()
class ProductListing extends Component {
  constructor(props) {
    super(props);
    loadLocales('collection')
    this.state = {
      hasMoreItems: true,
      listShop: [],
      size: 16,
      cancel: true,
      productInfo: [],
      current: 1,
      pages: 0,
      expandedKeys: [],
      selectedKeys: []
    };
    pageContext = this
  }
  componentWillMount() {
    this.fetchMoreItems();
    this.props.onChildEvent(this);
  }

  fetchMoreItems = () => {
    const { productInfo, listShop } = pageContext.state;
    if (productInfo.total <= listShop.length) {
      pageContext.setState({ hasMoreItems: false });
      return;
    }
    // a fake async api call

    if (pageContext.state.cancel) {
      pageContext.listShop();
    }
  };

  onShowSizeChange(current, pageSize) {
    pageContext.setState({ current: current, size: pageSize }, () => pageContext.listShop());
  };

  listShop(val) {
    let params = {
      current: pageContext.state.current.toString(),
      mainStoreKeyId: getMainStoreKeyId(),
      order: pageContext.props.sort,
      size: pageContext.state.size,
      sort: "sales",

    };
    if (val) {
      if (val.sortBy == "4") {
        params.sort = "price"
        params.order = "desc"
      } else if (val.sortBy == "3") {
        params.sort = "price"
        params.order = "asc"
      } else if (val.sortBy == "2") {
        params.order = "asc"
        params.sort = "sales"
      } else if (val.sortBy == "1") {
        params.order = "desc"
        params.sort = "sales"
      }
      if (val.pages) {
        params.pages = val.pages
      }
      if (val.current) {
        params.current = val.current
      }

    }
    if (this.props.propsState) {
      if (this.props.propsState.onSearch) {
        params.searchKey = this.props.propsState.onSearch
      }
      if (this.props.propsState.onSearchProductCode) {
        params.prodCode = this.props.propsState.onSearchProductCode
      }
    }

    const { cateCode } = this.state;
    if (cateCode) {
      params.cateCode = cateCode
    }
    pageContext.setState({ cancel: false });

    listShop(params).then(res => {
      pageContext.setState({
        cancel: true,
      });
      //接收后台返回的数据
      if (res && res.records) {
        putProductInfo(res.records);
        pageContext.setState({
          productInfo: res,
          listShop: res.records,
          pages: res.pages
        });
        pageContext.props.onRefresh();
      }
    });
    // let data = await listShop(params);
  }


  onPageCurrentChange(current, pageSize) {

    pageContext.setState({ current: current, size: pageSize }, () => pageContext.listShop());
  }

  onExpand = (expandedKeys, expanded) => {

    this.setState({ expandedKeys: expandedKeys });

  }
  onTreeSelect = (skey, e) => {

    let cateCode;
    let newExpandedKeys = [];
    // if (e.selectedNodes.length > 0 && e.node.children && e.node.children.length > 0) {
    cateCode = e.node.cateCode;
    newExpandedKeys = [e.node.key];
    this.setState({ cateCode, expandedKeys: newExpandedKeys, selectedKeys: newExpandedKeys }, () => {
      pageContext.listShop();
    });
    // } else if{
    // cateCode = false;
    // newExpandedKeys = []
    // }

  }


  onTreeSelectCateCode = (cateCode) => {

    this.setState({ cateCode }, () => {
      pageContext.listShop();
    });
  }

  switchTab(newKeyId) {
    const {
      tabskeys,
      listShopCate
    } = this.props;
    if (newKeyId !== "first") {
      if (listShopCate[newKeyId].children) {
        if (this.state.size == 16) {
          this.setState({ size: 12 });
        }
      } else {
        if (this.state.size == 12) {
          this.setState({ size: 16 });
        }
      }
    } else {
      this.setState({ size: 16 });
    }
  }
  render() {
    const {
      products,
      addToCart,
      symbol,
      addToWishlist,
      addToCompare,
      tabskeys,
      listShopCate,
    } = this.props;
    const { listShop, current, productInfo, size, cancel, expandedKeys } = this.state;
    let treeData = []
    const dig = (children) => {
      const list = [];
      // if (listShopCate[tabskeys] && listShopCate[tabskeys].children) {
      for (let i = 0; i < children.length; i++) {
        const key = children[i].key;
        const cateCode = children[i].cateCode;
        const treeNode = {
          title: children[i].title,
          cateCode,
          key,
        };
        if (children[i].children && children[i].children.length > 0) {
          treeNode.children = dig(children[i].children);
        }
        list.push(treeNode);
      }
      return list;
    };

    if (listShopCate[tabskeys] && listShopCate[tabskeys].children) {
      treeData = dig(listShopCate[tabskeys].children);
    }

    const pageSizeOptions = (tabskeys == "first" || treeData.length == 0 ? [16, 24, 32] : [12, 15, 21, 30])
    return (
      <div>
        <div className="product_list_division_sty">
          <div>
            {tabskeys == "first" || treeData.length == 0 ? "" : <div className="tree_son_sty" >
              <Tree
                expandedKeys={expandedKeys}
                autoExpandParent={true}
                onExpand={(expandedKeys, expanded) => this.onExpand(expandedKeys, expanded)}
                onSelect={(skey, e) => this.onTreeSelect(skey, e)}
                className="tree_sty"
                treeData={treeData}
                selectedKeys={this.state.selectedKeys} // 控制选中状态

              /*
              titleRender={(item) =>
                <Popover
                  placement="right"
                  style={{ fontSize: "14px" }}

                  content={
                    item.children && item.children.length > 0 ? item.children.map(item => <div className="popover_style">{item.title}</div>) : ""
                  } >
                  {item.title}
                </Popover >}
                */
              // defaultExpandAll
              />
            </div>}
            {
              !cancel ? <div className='product_list_spin'><Spin /></div> :
                listShop.length === 0 ? (
                  <div className="row row_width">
                    <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                        className="img-fluid mb-4"
                        onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
                      />
                      <h3>{intl.get("ProductNotFound")}</h3>
                      <p>
                        {intl.get("pleaseCheckIfYouMadeAnyMistakes")}
                      </p>
                      {/* <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="btn btn-solid"
                  >
                    继续购物
                  </Link> */}
                    </div>
                  </div>
                ) : (
                  <Row className={tabskeys == "first" ? "listShopRow" : "tabs_info_sty listShopRow"}>
                    {listShop.map((product, index) => (
                      <Col span={tabskeys == "first" ? 6 : 8}
                        key={index}>

                        <ProductListItem
                          product={product}
                          symbol={symbol}
                          onAddToCompareClicked={() => addToCompare(product)}
                          onAddToWishlistClicked={() => addToWishlist(product)}
                          onAddToCartClicked={addToCart}
                          key={index}
                        />
                      </Col>
                    ))}
                  </Row>
                )}

          </div>
          <Pagination
            className="pagination_sty"
            showSizeChanger
            onChange={this.onPageCurrentChange}
            pageSizeOptions={pageSizeOptions}
            current={current} total={productInfo.total} pageSize={size} />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: getVisibleproducts(state.data, state.filters),
  symbol: state.data.symbol
});

export default connect(mapStateToProps, {
  addToCart,
  addToWishlist,
  addToCompare
})(ProductListing);

import React, { Component } from "react";
import { connect } from "dva";
import { Link, } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumb from "../common/breadcrumb";
import { getDefaultDdress, getDdressList, placeOrder, listCar, refreshPayStatus, payQrCode, cancelOrder, getDetail } from '@/utils/user.js'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Input, Table, Spin } from 'antd';
import './index.css'
import DeliveryAddressModel from '@/components/checkout/DeliveryAddressModal'
import PayModal from './payModal'
import { getCurCode } from "@/storage/LocalParamers";
import { getOwnerKeyId, getShoppingCartSelected } from "@/storage/SessionParamers";
import { getToken } from "../../storage/LocalParamers";
import { purchaseNowDelect, cartNumberDelect, cartNumber } from "@/actions";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { getMainStoreKeyId } from "@/storage/SessionParamers"

const { confirm } = Modal
const { TextArea } = Input
const errorPhoto = require('@/images/errorPhoto.png')

let pageContext
class checkOut extends Component {
  constructor(props) {
    super(props);
    loadLocales('checkout')
    this.state = {
      defaultDdress: false,
      shopCarVOList: [],
      listCars: false,
      openState: false,
      ddressList: [],
      selectAndUseDdress: false,
      symbol: getCurCode(),
      payQRcodeState: false,
      payInfo: false,
      placeOrderRes: false,
      payQrCoderes1: false,
      loading: false,
      closeModal: false,
      payQrCodeIndex: 0,
      shoppingCartSelected: false,
      newListCars: false
    };
    pageContext = this
    this.onUseAnotherAddressHandleCancel = this.onUseAnotherAddressHandleCancel.bind(this)
  }

  componentDidMount() {
    // this.getClientDisc()
    this.getDefaultDdress();
    this.getPlaceOrderData();
    // this.listCars();

  }
  componentWillUnmount() {
    clearInterval(this.getPayQrCodeInterval)
    clearInterval(this.payQrCodeFun)
  }
  getPlaceOrderData = () => {
    const { purchaseNowProduct, defaultDdress } = this.props;

    if (purchaseNowProduct.bought) {

    } else
      if (purchaseNowProduct) {

        this.reorganisationOfSubmissions(purchaseNowProduct)
      } else {
        const paidGoods = getShoppingCartSelected()

        if (parseFloat(paidGoods.amtValue) < 200) {
          confirm({
            title: intl.get("‌FriendlyReminder"),
            content: intl.get('placeTip'),
            cancelText: intl.get('OrderCancellation'),
            okText: intl.get('RepeatOrder'),
            onOk() {
              const params = {
                // prodCode: "0533320",
                skuKeyId: "3992c9c6d4bd8b3dd4b2d44f685f0210",
              }

              getDetail(params).then(res => {
                if (res && res.keyId) {

                  const prodInfo = {
                    detailRemark: null,
                    imageUrl: res.imageUrl,
                    key: res.keyId,
                    keyId: res.keyId,
                    loginUserKeyId: pageContext.state.defaultDdress.userKeyId,
                    mainPicture: res.mainPicture,
                    produceName: res.goodsName,
                    producePrice: res.price,
                    quantity: 1,
                    relationGoodKeyId: res.relationGoodKeyId,
                    skuKeyId: res.keyId,
                    specialPrice: res.specialPrice,
                    specialStatus: res.specialStatus,
                    tolalAmount: res.price,
                    tolalSpecialPrice: res.specialPrice,
                    // unitName: res.goodsName
                  }
                  let carsInfo = paidGoods;
                  carsInfo.selected.push(prodInfo)
                  let discAmount = 0;
                  if (prodInfo.specialStatus == 1) {
                    carsInfo.totalDiscountAmount = prodInfo.producePrice + carsInfo.totalDiscountAmount;
                  }
                  else {
                    carsInfo.normalAmt = prodInfo.producePrice + carsInfo.normalAmt;
                    let clientDisc = (carsInfo && carsInfo.clientDisc && parseFloat(carsInfo.clientDisc) >= 0) ? carsInfo.clientDisc : 0
                    discAmount = prodInfo.producePrice * (clientDisc * 0.01)
                  }

                  carsInfo.amtValue = carsInfo.amtValue + prodInfo.producePrice - discAmount;
                  carsInfo.totalOriginalAmount = prodInfo.producePrice + carsInfo.totalOriginalAmount;
                  carsInfo.normalDiscAmt = carsInfo.normalDiscAmt + discAmount;
                  let listCars = carsInfo.selected;

                  pageContext.setState({
                    listCars: carsInfo,
                    newListCars: listCars
                  });
                }
                //接收后台返回的数据

              });
            },
            onCancel() {
              pageContext.props.history.goBack()
            }
          })

          // this.listCars();
        } else {
          this.setState({
            listCars: paidGoods,
            newListCars: paidGoods.selected
          });
        }

      }
  }

  onTotalNumberFu = (purchaseNowProduct) => {
    let num = 0
    for (const val of purchaseNowProduct) {
      num += val.quantity
    }
    if (typeof (num) != "Nan") {
      return num
    } else {
      return 0
    }
  }

  onPriceFu = (purchaseNowProduct, type) => {

    let num = 0
    for (const val of purchaseNowProduct) {
      if (type == "1") {
        num += val.specialPrice
      } else if (type == "2") {
        num += (val.producePrice * val.quantity)
      } else if (type == "3" && val.specialStatus == 1) {
        num += ((val.producePrice * val.quantity) - (val.specialPrice * val.quantity))
      } else if ((type == "4" || type == "5" || type == "6") && val.specialStatus == 0) {
        num += (parseFloat((val.quantity * val.producePrice)))
      } else if (type == "7") {
        if (val.specialStatus == 0) {
          parseFloat((val.producePrice * val.quantity))
        } else {
          parseFloat((val.specialPrice * val.quantity))
        }
      } else if (type == "8" && val.specialStatus == 1) {
        num += parseFloat((val.quantity * val.specialPrice))
      }
      // else if (purchaseNowProduct.specialStatus == 0) {
      //   num += (parseFloat((purchaseNowProduct.qty * purchaseNowProduct.price)))
      // }


    }

    if (num != "Nan") {
      return num.toFixed(1)
    } else {
      return 0.00
    }
  }

  reorganisationOfSubmissions = (purchaseNowProduct) => {

    let listCars = {
      prodQty: this.onTotalNumberFu(purchaseNowProduct),
      specialPrice: this.onPriceFu(purchaseNowProduct, "1"),
      totalOriginalAmount: this.onPriceFu(purchaseNowProduct, "2"),
      // totalDiscountAmount: this.onPriceFu(purchaseNowProduct, "3"),
      normalAmt: this.onPriceFu(purchaseNowProduct, "4"),
      tolalAmount: this.onPriceFu(purchaseNowProduct, "5"),

      normalDiscAmt: this.onPriceFu(purchaseNowProduct, "6"),

      amtValue: this.onPriceFu(purchaseNowProduct, "7"),

      totalDiscountAmount: this.onPriceFu(purchaseNowProduct, "8"),
      shopCarVOList: purchaseNowProduct,
    }
    this.setState({
      shopCarVOList: purchaseNowProduct,
      listCars
    });


  }

  getClientDisc = () => {
    listCar().then(res => {
      if (res && res.responseCode == "00") {

        // this.setState({
        //   listCars: res.data
        // });
      }
    });
  }

  listCars = () => {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList) {
          this.setState({
            shopCarVOList: res.data.shopCarVOList
          });
        } else {
          this.props.cartNumber(0)
        }
        let listCars = res.data.shopCarVOList;
        let newListCars = [];
        for (const val of listCars) {
          for (const selectVal of getShoppingCartSelected()) {
            if (val.keyId == selectVal) {
              newListCars.push(val)
            }
          }

        }
        this.reorganisationOfSubmissions(newListCars)
        this.setState({
          listCars: res.data,
          newListCars
        });
      }
    });
  }

  getDefaultDdress() {
    getDefaultDdress().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data) {
          pageContext.setState({ defaultDdress: res.data, selectAndUseDdress: res.data, });
        }
      }
    })
  }
  onUseNewAddress() {
    getDdressList().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data && res.data.length > 0) {
          pageContext.setState({ selectAndUseDdress: res.data[0] });
        }
      }
    })
  }

  onUseAnotherAddressHandle() {
    getDdressList().then(res => {
      if (res && res.responseCode == "00") {
        const defaultCheckbox = res.data.filter(item => item.isDefault == "1").map(item => item.keyId)
        pageContext.setState({ ddressList: res.data, openState: true });
      }
    })
  }
  onUseAnotherAddressHandleCancel(item) {
    if (typeof item === "number") {
      pageContext.setState({ selectAndUseDdress: this.state.ddressList[item] })
    }
    pageContext.setState({ openState: false })
  }

  onPayQRcodeCancel = (item) => {
    clearInterval(this.getPayQrCodeInterval)
    clearInterval(this.payQrCodeFun)
    pageContext.setState({ payQRcodeState: false })
    // pageContext.props.history.goBack()
    this.props.history.replace(`${process.env.PUBLIC_URL}/pages/order-success`)

  }

  onCheckbox(checkedValue) {
  }

  onPlaceOrder = () => {
    const { selectAndUseDdress, listCars, newListCars } = pageContext.state;
    if (!selectAndUseDdress) {
      toast.error(intl.get("SelectShippingAddress"))
      return
    }
    const amount = listCars.amtValue;
    if (amount <= 0 || !amount) {
      toast.error(intl.get("pleaseReorder"));
      return
    }
    const shopCarVOList = newListCars
    const param = {
      address: selectAndUseDdress.recieveAddress,
      amtValue: amount.toFixed(1),
      poValue: parseFloat(listCars.totalOriginalAmount),
      contactName: selectAndUseDdress.recieveName,
      contactTel: selectAndUseDdress.phone,
      discValue: listCars && listCars.clientDisc >= 0 ? listCars.clientDisc : "0",
      mainStoreKeyId: getMainStoreKeyId(),
      ownerKeyId: getOwnerKeyId(),
      shopCarVOList,
      storeKeyId: getMainStoreKeyId(),
      orderRemark: listCars.orderRemark
    }

    placeOrder(param).then(res => {

      if (res && res.responseCode == "00" && res.data) {
        this.props.purchaseNowDelect();
        this.props.cartNumberDelect();
        this.setState({ placeOrderRes: res }, () => {
          this.genpay()
        })
      }
    })



  }


  genpay = () => {
    clearInterval(pageContext.getPayQrCodeInterval)

    const { placeOrderRes, payQrCoderes1, payQrCodeIndex } = pageContext.state;
    let payInfo = placeOrderRes.data;
    const payQrCodeInfo = {
      orderKeyId: placeOrderRes.data.orderKeyId,
      orderNo: placeOrderRes.data.orderNo,
      payAmount: placeOrderRes.data.payAmount,
    }
    payQrCode(payQrCodeInfo).then(payQrCoderes1 => {

      if (payQrCoderes1 && payQrCoderes1.responseCode == "00") {
        if (payQrCoderes1.data.payQrcode != "") {
          // if (payQrCoderes1.data.payStatus == 0) {
          payInfo.payQrcode = payQrCoderes1.data.payQrcode;
          payInfo.payRecordKeyId = payQrCoderes1.data.payRecordKeyId
          pageContext.setState({ loading: false, payQRcodeState: true, payInfo, payQrCoderes1, payQrCodeIndex: 0 }, () => {
            pageContext.payQrCodeFun = setInterval(() => pageContext.getRefreshPayStatus(), 5000)
          });

          // }
          // else {
          //   this.setState({ payInfo, })
          // }
        } else {
          payInfo.payRecordKeyId = payQrCoderes1.data.payRecordKeyId;
          pageContext.setState({ payQrCoderes1, payQrCodeIndex: 1, payInfo, loading: true }, () => {
            pageContext.getPayQrCodeInterval = setInterval(() => pageContext.getpayCode(), 3000)

          })

        }
      } else {
        toast.error(`${payQrCoderes1.responseMessage}!`);
      }
    })

  }

  getpayCode = () => {
    const { payInfo } = this.state
    let newPayInfo = payInfo

    payQrCode(payInfo).then(payQrCoderes1 => {
      if (payQrCoderes1 && payQrCoderes1.responseCode == "00") {
        // if (payQrCoderes1.data.payStatus === 0) {
        if (payQrCoderes1.data.payQrcode != "") {

          clearInterval(this.getPayQrCodeInterval)
          newPayInfo.payQrcode = payQrCoderes1.data.payQrcode;
          newPayInfo.payRecordKeyId = payQrCoderes1.data.payRecordKeyId
          this.setState({ loading: false, payQRcodeState: true, payInfo: newPayInfo, payQrCoderes1, payQrCodeIndex: 0 }, () => {
            this.payQrCodeFun = setInterval(() => this.getRefreshPayStatus(payQrCoderes1), 5000)
          });
        } else if (payQrCoderes1.data.payStatus === 2) {
          clearInterval(this.getPayQrCodeInterval)
          toast.info("生成支付码失败!")
        }
      }
    })
  }

  getRefreshPayStatus = () => {
    const { placeOrderRes, payQrCoderes1 } = this.state
    if (payQrCoderes1) {
      const refreshPayInfos = {
        orderKeyId: placeOrderRes.data.orderKeyId,
        orderNo: placeOrderRes.data.orderNo,
        payAmount: placeOrderRes.data.payAmount,
        payRecordKeyId: payQrCoderes1.data.payRecordKeyId,
      }
      refreshPayStatus(refreshPayInfos).then(payQrCoderes2 => {
        if (payQrCoderes2 && payQrCoderes2.responseCode == "00") {
          if (payQrCoderes2.data.payStatus === "1") {
            clearInterval(this.payQrCodeFun)
            this.props.history.replace(`${process.env.PUBLIC_URL}/pages/order-success`)
          } else if (payQrCoderes2.data.payStatus === "2") {
            toast.error(intl.get("paymentFailure"));
            clearInterval(this.payQrCodeFun)
          }
        } else {
          clearInterval(this.payQrCodeFun)
        }
      })
    }

  }
  cancelOrderFun = () => {
    const { payInfo } = this.state
    const cancelOrderInfos = {
      orderKeyId: payInfo.orderKeyId,
      orderNo: payInfo.orderNo,
      payAmount: payInfo.payAmount,
      payRecordKeyId: payInfo.payRecordKeyId,
    }

    cancelOrder(cancelOrderInfos).then(res => {
      this.onCancelClose()
      if (res && res.responseCode === "00") {
        toast.error(intl.get("OrderCancellationSuccess"), 5000);
        clearInterval(this.getPayQrCodeInterval)
        clearInterval(this.payQrCodeFun)
        pageContext.setState({ payQRcodeState: false })
        this.props.history.replace("/no-sidebar/collection")

      }
    })
  }

  handleOkClose = () => {
    this.cancelOrderFun()
  }

  openCloseModal = () => { this.setState({ closeModal: true }) }
  onCancelClose = () => {
    this.setState({ closeModal: false });

  }

  inputOrderDetailRemarkList = (index, e) => {
    const { shopCarVOList, newListCars } = this.state
    newListCars[index].detailRemark = e.target.value
    this.setState({ newListCars });
  }

  setOrderRemark = (e) => {
    const { listCars } = pageContext.state;
    listCars.orderRemark = e
    this.setState({ listCars });
  }


  render() {
    const {
      defaultDdress,
      shopCarVOList,
      newListCars,
      openState,
      ddressList,
      selectAndUseDdress,
      symbol,
      listCars,
      payQRcode,
      payInfo,
      payQRcodeState,
      loading,
      closeModal
    } = this.state;
    const { purchaseNowProduct } = this.props;
    const columns = [
      {
        title: intl.get("sequence"),
        dataIndex: 'serials',
        key: 'serials',
        width: 70,
        render: (text, record, index) => <div>{index + 1}</div>,
        align: "center"
      },
      {
        title: intl.get("productImage"),
        key: 'pictures',
        width: 150,
        align: "center",
        render: (item) =>
          <img
            className="cart_pictures"
            src={!item && item.mainPicture ? "" : item.mainPicture}
            alt=""
            onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
          />
      },
      {
        title: intl.get('productName'),
        dataIndex: 'produceName',
        key: 'name',
        align: "left",
        width: 300,
      },
      {
        title: intl.get("price"),
        key: 'price',
        width: 120,
        align: "right",
        render: item =>
          <div>
            <div
              className="specialPrice"
              style={{ display: item.specialStatus == 0 ? "none" : "" }}>
              {symbol} {item.specialPrice}
              <div>{intl.get("specialOffers")}</div>
            </div>
            <h2 style={{ display: item.specialStatus == 0 ? "" : "none" }}>
              {symbol} {item.producePrice}
            </h2>
          </div >
      },
      {
        title: intl.get('quantity'),
        key: 'quantity',
        align: "center",
        width: 180,
        render: (text, record, index) =>


          <div className='col_lamination'>
            <h4 style={{ textAlign: "center" }}>
              x {text.quantity}
            </h4>
            <div className='remark_style' >{intl.get("remark")}:</div>
          </div>
      },
      {
        title: intl.get("total"),
        key: 'total',
        width: 170,
        align: "right",
        render: (text, record, index) =>
          <div className='col_lamination'>
            <div >
              <div
                style={{ display: text.specialStatus == 0 ? "none" : "" }}
                className={text.specialStatus == 0 ? "" : "specialPrice"}>

                {symbol} {text.tolalSpecialPrice}
              </div>
              <h2
                style={{ display: text.specialStatus == 0 ? "" : "none" }}
              >
                {symbol} {text.tolalAmount}
              </h2>
            </div >
            <div >
              <Input
                style={{ backgroundColor: "#f2f2f2", border: "#f2f2f2" }}
                value={text.detailRemark} onChange={e => this.inputOrderDetailRemarkList(index, e)} />
            </div >



          </div>
      },

    ];
    return (

      <div style={{ position: "relative" }}>
        {loading ? <div className="closeloading" onClick={this.openCloseModal}>
          <CloseCircleOutlined style={{ fontSize: "30px" }} />
        </div> : ""}

        <Modal title={intl.get("RetrievingPaymentInformation")} open={closeModal} onOk={this.handleOkClose} onCancel={this.onCancelClose} >
          <p>{intl.get("WouldCancelOrder")}</p>
        </Modal>
        <Spin spinning={loading}>
          <section className="section-b-space">
            <div className="containermb1">
              <div className="title_order_confirmation">{intl.get("deliveryAddress")}</div>
              <div className="delivery_address_sty">
                {!selectAndUseDdress ?
                  <div>{intl.get('NoShippingAddressAvailable')}</div>
                  :
                  <div>
                    <div>
                      <div>{selectAndUseDdress.recieveName}</div>
                      <div>{selectAndUseDdress.phone}</div>
                      {selectAndUseDdress.isDefault === 1 ? <div className="default_address_sty">{intl.get("default")}</div> : ""}

                    </div>
                    <div className="address_sty">{selectAndUseDdress.recieveAddress}</div>
                  </div>
                }
                <div onClick={() => this.onUseAnotherAddressHandle()} className="add_address_btn">{intl.get('useAnotherAddress')}</div>

              </div>
              <div className="title_order_confirmation">{intl.get("productList")}</div>

              <Table scroll={{
                x: 1010, y: "100%"
              }} columns={columns}
                dataSource={newListCars}
                pagination={false} />
              <div>
                <div style={{ width: "100%" }} className="myOrder_operation fontSize-16">
                  <div>
                    {intl.get("orderRemark")}
                  </div>
                  <div>
                    <TextArea
                      value={listCars.orderRemark}
                      onChange={(e) => this.setOrderRemark(e.target.value)}
                      style={{ backgroundColor: "#f2f2f2", border: "#f2f2f2" }}
                      placeholder={intl.get("PleaseEnterNoteForYourOrder")}
                      autoSize={{
                        minRows: 4,
                        maxRows: 4,
                      }} />
                  </div>
                </div>
                <div className="amount_statistics_sty">
                  <div>
                    <div>{intl.get("NumberItems")}<span className="font_color_grey">{listCars.prodQty}</span></div>
                    <div>{intl.get("specialOfferTotal")}
                      <span className="font_color_red">
                        <span>{symbol}</span>{listCars && listCars.totalDiscountAmount ? parseFloat(listCars.totalDiscountAmount).toFixed(1) : "0"}</span></div>
                    <div>{intl.get("regularTotal")}
                      <span className="font_color_grey"><span>{symbol}</span>{listCars && listCars.normalAmt ? parseFloat(listCars.normalAmt).toFixed(1) : "0.00"}</span></div>
                  </div>
                  <div>
                    {purchaseNowProduct ? "" : <div>
                      {intl.get("regularItemDiscount")}(-{listCars && parseFloat(listCars.clientDisc) >= 0 ? listCars.clientDisc : "0"}%)
                      <span className="offer_amount">
                        <span>{symbol}</span>
                        {listCars && listCars.normalDiscAmt >= 0 ? parseFloat(listCars.normalDiscAmt).toFixed(1) : "0"}</span>
                    </div>}

                  </div>
                  <div>
                    <div>
                      {intl.get("orderAmount")}
                      <span className="font_color_blue">
                        <span>{symbol}</span>{listCars && listCars.amtValue ? parseFloat(listCars.amtValue).toFixed(1) : "0"}
                      </span>
                    </div>
                  </div>
                </div>

              </div>
              <div className="operation_sty">
                <div>

                </div>
                <div>
                  <Link
                    onClick={() => this.props.history.goBack()}
                    className="btn btn-solid keep_shopping_sty"
                  >
                    {intl.get("back")}
                  </Link>

                  <div className="btn btn-solid"
                    onClick={() => this.onPlaceOrder()}
                  >
                    {intl.get("SubmitOrder")} </div>
                </div>

              </div>
            </div>
            <DeliveryAddressModel
              ref={this.childRef}
              openState={openState}
              ddressList={ddressList}
              defaultDdress={defaultDdress}
              onUseNewAddress={this.onUseNewAddress}
              onUseAnotherAddressHandleCancel={this.onUseAnotherAddressHandleCancel}
            />
            <PayModal
              payQRcodeState={payQRcodeState}
              onPayQRcodeCancel={this.onPayQRcodeCancel}
              payInfo={payInfo}
              history={this.props.history}
              cancelOrderFun={this.cancelOrderFun}
            >

            </PayModal>
          </section>
        </Spin>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    purchaseNowProduct: state.purchaseNow.purchaseNowProduct
  };
};

export default connect(mapStateToProps, {
  purchaseNowDelect, cartNumberDelect, cartNumber
})(checkOut);



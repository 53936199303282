import React, { Component } from "react";
import { toast } from "react-toastify";

import Breadcrumb from "../../common/breadcrumb";
import "../login.css";
import { smsSend, shopUserRegister } from "@/utils/user";
import RegisterPage from '@/componentsMobile/register/registerPage'
import RegistrationSuccessful from '@/componentsMobile/register/registrationSuccessful'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { getMainStoreKeyId, getOwnerKeyId } from "@/storage/SessionParamers"
import './registermb.css';
let pageContext
class Register extends Component {

  constructor(props) {
    super(props);
    loadLocales('pages')
    this.state = {
      yztime: 179, areaCode: "853", fname: "", lname: "",
      registrationSuccessful: false,
      loading: false,
      mobilePhoneNumber: false
    };
    pageContext = this
    this.formData = React.createRef()
  }

  componentWillUnmount() {
    // 组件即将卸载，执行清理工作
    // 例如，清除定时器，取消网络请求，或者清理任何此组件专有的资源
    if (this.siv) {
      clearInterval(this.siv)
    }
  }

  smsSend = () => {
    const { areaCode, mobilePhoneNumber } = this.state
    if (!mobilePhoneNumber) {
      toast.error(intl.get("PleaseProvideYourPhoneNumber"));
      return
    }
    let params = {
      phone: areaCode + "-" + mobilePhoneNumber
    };
    pageContext.count();
    smsSend(params).then(data => {
      if (data.responseCode == "00") {
        pageContext.setState({ data: data.data, loading: true });
        pageContext.count();
      }
    })
  }

  handleOnOK() {
    const { loginPassword, confirmPassword } = pageContext.state;
    if (loginPassword != confirmPassword) {
      toast.error(intl.get("PleaseRe-enter"));
      return;
    }
    pageContext.shopUserRegister();
  }

  shopUserRegister() {
    const {
      loginPassword,
      confirmPassword,
      mobilePhoneNumber,
      areaCode,
      verificationCode,
      fname,
      lname
    } = pageContext.state;
    let params = {
      mobilePhoneNumber: mobilePhoneNumber,
      areaCode: areaCode,
      confirmPassword: confirmPassword,
      loginPassword: loginPassword,
      mainStoreKeyId: getMainStoreKeyId(),
      nickName: fname,
      ownerKeyId: getOwnerKeyId(),
      verificationCode: verificationCode
    };
    shopUserRegister(params).then(data => {
      if (data.responseCode == "00") {
        pageContext.setState({ registrationSuccessful: true });
        toast.success(data.responseMessage);
        pageContext.props.history.goBack()
      } else {
        toast.error(data.responseMessage);
      }

    })

  }

  onInputPhoneChange(e) {
    pageContext.setState({ mobilePhoneNumber: e.target.value });
  }
  onInputCaptchaChange(e) {
    pageContext.setState({ verificationCode: e.target.value });
  }

  onSelectCountryCode(e) {
    pageContext.setState({ areaCode: e });
  }

  onInputFnameChange(e) {
    pageContext.setState({ fname: e.target.value });
  }

  onInputLnameChange(e) {
    pageContext.setState({ lname: e.target.value });
  }

  onInputLoginPasswordChange(e) {
    pageContext.setState({ loginPassword: e.target.value });
  }

  onInputConfirmPasswordChange(e) {
    pageContext.setState({ confirmPassword: e.target.value });
  }

  count() {
    let { yztime } = pageContext.state;
    this.siv = setInterval(() => {
      pageContext.setState({ yztime: yztime-- }, () => {
        if (yztime <= -1) {
          clearInterval(this.siv); //倒计时( setInterval() 函数会每秒执行一次函数)，用 clearInterval() 来停止执行:
          pageContext.setState({ loading: false, yztime: 180 });
        }
      });
    }, 1000);
  }
  render() {
    const {
      loading,
      yztime,
      mobilePhoneNumber,
      verificationCode,
      lname,
      fname,
      areaCode,
      registrationSuccessful
    } = this.state;
    return (
      <div className="mobile-register-container">
        <div className="container mobile-adaptive-container">
          {!registrationSuccessful ? <RegisterPage
            fname={fname}
            loading={loading}
            onSelectCountryCode={this.onSelectCountryCode}
            onInputLoginPasswordChange={this.onInputLoginPasswordChange}
            onInputFnameChange={this.onInputFnameChange}
            mobilePhoneNumber={mobilePhoneNumber}
            onInputPhoneChange={this.onInputPhoneChange}
            onInputConfirmPasswordChange={this.onInputConfirmPasswordChange}
            verificationCode={verificationCode}
            onInputCaptchaChange={this.onInputCaptchaChange}
            yztime={yztime}
            smsSend={this.smsSend}
            handleOnOK={this.handleOnOK}
          ></RegisterPage> :
            <RegistrationSuccessful history={this.props.history}></RegistrationSuccessful>
          }
        </div>
        <div className="mobile-safe-area" />
      </div >
    );
  }
}

export default Register;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import "./index.scss";
import "babel-polyfill";
// Import custom components
import store from "./store";
import translations from "./constants/translations";
import { getAllProducts } from "./actions";
// Layouts
import Kids from "./components/layouts/kids/main";
import MobileKids from "./componentsMobile/layouts/kids/main";
//Collection Pages

import CollectionNoSidebar from "./components/collection";
import CollectionNoSidebarMobile from "./componentsMobile/collection";

// Product Pages
import NoSideBar from "./components/products";
import NoSideBarMb from "./componentsMobile/products";
// Features
import Layout from "./components/app";
import LayoutMobile from "./componentsMobile/app";
import Cart from "./components/cart";
import CartMb from "./componentsMobile/cart";
import checkOut from "./components/checkout";
import checkOutMb from "./componentsMobile/checkout";
// Extra Pages
import aboutUs from "./components/pages/about-us";
import PageNotFound from "./components/pages/404";
import lookbook from "./components/pages/lookbook";
import Login from "./components/pages";
import LoginMb from "./componentsMobile/pages";
import Register from "./components/pages/Register";
import RegisterMb from "./componentsMobile/pages/Register";
import Search from "./components/pages/search";
import Collection from "./components/pages/collection";
import ForgetPassword from "./components/pages/forget-password";
import ForgetPasswordMb from "./componentsMobile/pages/forget-password";
import Contact from "./components/pages/contact";
import Dashboard from "./components/pages/dashboard";
import Faq from "./components/pages/faq";
// Portfolio Features
import ForgottenPassword from "./components/forgottenPassword";
import OrderSuccess from "./components/OrderSuccess";
import OrderSuccessMb from "./componentsMobile/OrderSuccess";
import PersonalCentre from "./components/PersonalCentre";
import PersonalCentreMb from "./componentsMobile/PersonalCentre";
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider } from 'antd';
import intl from "react-intl-universal";

// 判断h5还是pc true就是h5
let client =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
//alert(client);
//client = false
class Root extends React.Component {
  render() {
    store.dispatch(getAllProducts());
    return (
      <ConfigProvider theme={{ cssVar: true, hashed: false }} locale={zhCN}>
        <Provider store={store}>
          <IntlProvider translations={translations} locale="en">
            <BrowserRouter basename={"/"}>
              <ScrollContext>
                <Switch>
                  <Route
                    exact
                    path={`/`}
                    component={client ? MobileKids : Kids}
                  />
                  {client ? <LayoutMobile>
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/personal-centre`}
                      component={PersonalCentreMb}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                      component={CollectionNoSidebarMobile}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`}
                      component={NoSideBarMb}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/cart`}
                      component={CartMb}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/checkout`}
                      component={checkOutMb}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/sales/orders`}
                      component={aboutUs}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/about-us`}
                      component={aboutUs}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/404`}
                      component={PageNotFound}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/lookbook`}
                      component={lookbook}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/login`}
                      component={LoginMb}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/order-success`}
                      component={OrderSuccessMb}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/register`}
                      component={RegisterMb}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/forgotten-password`}
                      component={ForgetPasswordMb}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/search`}
                      component={Search}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/collection`}
                      component={Collection}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/forget-password`}
                      component={ForgetPassword}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/contact`}
                      component={Contact}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/dashboard`}
                      component={Dashboard}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/faq`}
                      component={Faq}
                    />
                  </LayoutMobile>
                    :
                    <Layout>
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/personal-centre`}
                        component={PersonalCentre}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                        component={CollectionNoSidebar}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`}
                        component={NoSideBar}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/cart`}
                        component={Cart}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/checkout`}
                        component={checkOut}
                      />

                      <Route
                        path={`${process.env.PUBLIC_URL}/sales/orders`}
                        component={aboutUs}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/about-us`}
                        component={aboutUs}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/404`}
                        component={PageNotFound}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/lookbook`}
                        component={lookbook}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/login`}
                        component={Login}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/order-success`}
                        component={OrderSuccess}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/register`}
                        component={Register}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/forgotten-password`}
                        component={ForgottenPassword}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/search`}
                        component={Search}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/collection`}
                        component={Collection}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/forget-password`}
                        component={ForgetPassword}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/contact`}
                        component={Contact}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/dashboard`}
                        component={Dashboard}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/faq`}
                        component={Faq}
                      />
                    </Layout>
                  }

                </Switch>
              </ScrollContext>
            </BrowserRouter>
          </IntlProvider>
        </Provider>
      </ConfigProvider >

    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual'

// Custom Components
import HeaderOne from './common/headers/header-one';

import FooterOne from "./common/footers/footer-one";

// ThemeSettings
import ThemeSettings from "./common/theme-settings"
import { withRouter } from "react-router-dom";
import './App.css';
class App extends Component {

  render() {

    return (

      <div className="layout-container" key={this.props.location.key}>
        <HeaderOne logoName={'logo.png'} />
        {this.props.children}
        <FooterOne logoName={'logo.png'} />
        <ThemeSettings />

      </div>
    );
  }
}

export default withRouter(App);

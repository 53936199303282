import React, { Component } from "react";
import Slider from "react-slick";
import "../common/index.scss";
import { putProductInfo } from '@/storage/PageParamers'
import ProductListing from "./common/product-listing";
import Breadcrumb from "../common/breadcrumb";
import { ConfigProvider, Input, Select, Button, TreeSelect } from 'antd'
import { listShopCate, listSpecProd } from '@/utils/user'
import './collection-no-sidebar.css'
import './collection.scss'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { DownOutlined } from '@ant-design/icons';
let pageContext
const { Search } = Input;
class CollectionNoSideBar extends Component {
  constructor(props) {
    super(props);
    loadLocales('collection')
    this.state = {
      layoutColumns: 3,
      listShop: [],
      refresh: true,
      tabskeys: "first",
      listShopCate: []
      ,
      selectedKeys: null,
      value: "" // 添加你要用的 value 状态
    };
    pageContext = this

  }

  componentDidMount() {
    pageContext.listShopCate();
  }
  listShopCate() {
    listShopCate().then(res => {
      //接收后台返回的数据
      if (res && res.length > 0) {
        // putProductInfo(res);
        pageContext.setState({ listShopCate: res });
      }
    });
    // let data = await listShop(params);
  }

  LayoutViewClicked(colums) {
    pageContext.setState({
      layoutColumns: colums
    });
  }
  onRefresh = () => {
    pageContext.setState({ refresh: !pageContext.state.refresh });
  };

  onSortBy = sort => {
    // 通过子组件的实例调用组组件中的方法
    pageContext.childRef.listShop();
    pageContext.setState({
      sort: sort,
      refresh: !pageContext.state.refresh
    });
  };

  handleChildEvent = ref => {
    // 将子组件的实例存到 pageContext.childRef 中, 这样整个父组件就能拿到
    pageContext.childRef = ref;
  };

  handleChange = (value) => {
    pageContext.childRef.listShop({ sortBy: value });
  };

  onSearch = (e) => {
    this.setState({ onSearch: e.target.value });
  }

  onSearchProductCode = (e) => {
    this.setState({ onSearchProductCode: e.target.value });

  }

  onSearchProduct = () => {
    const { onSearch, onSearchProductCode } = this.state
    let searchObj = {
      onSearch,
      onSearchProductCode,
      pages: 1,
      current: "1"
    }

    pageContext.childRef.listShop(searchObj);
  }

  onSearchReset = () => {
    this.setState({ onSearchProductCode: null, onSearch: null });
  }

  onKeyPressSearch = (e) => {
    if (e.key === 'Enter') {
      // 在这里处理回车键事件
      const { onSearch, onSearchProductCode } = this.state
      let searchObj = {
        onSearch,
        onSearchProductCode,
        pages: 1,
        current: "1"
      }

      pageContext.childRef.listShop(searchObj);
    }
  }


  listSpecProd() {
    let params = {};
    listSpecProd(params).then(res => {
      if (res) {
        pageContext.setState({ listSpecProd: res.records }, () => { pageContext.setState({ refresh: !pageContext.state.refresh }) });
        putProductInfo(res.records);
      }
    });
  }



  render() {
    const { tabskeys, listShopCate,
      onSearch,
      onSearchProductCode } = pageContext.state
    // 转换数据格式为 TreeSelect 需要的结构
    const convertToTreeData = (data) => {
      return data.map(item => ({
        title: (
          <div style={{ padding: "9px 15px", fontSize: "17px" }}>
            {item.title}
          </div>
        ),
        value: item.cateCode,
        children: item.children ? convertToTreeData(item.children) : null,
        disableCheckbox: item.children?.length > 0, // 可选：禁止选择父节点
      }));
    };

    // 生成最终数据（包含你的第一个特殊项）
    const treeData = [
      {
        title: <div style={{ padding: "9px 15px", fontSize: "17px" }}>{intl.get("allProducts")}</div>,
        value: '',
      },
      ...convertToTreeData(listShopCate)
    ];



    const { value } = this.state;


    // 点击分类时的处理
    const handleChange = (selectedValue) => {
      const { value } = this.state
      if (value !== selectedValue) {
        pageContext.setState({ value: selectedValue });
        let cateCode = selectedValue;
        pageContext.childRef.onTreeSelectCateCode(cateCode)
      }
    };

    return (
      <div>
        {/*Section Start*/}
        <section className="section-b-space">
          <div className="product-list-container">
            <div className="containermb1">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#EE1D23',
                  },
                }}
              >


                <div className="search-container">
                  <div className="search-fields" onKeyPress={this.onKeyPressSearch}>
                    <Input allowClear onChange={this.onSearch} className="search_input"
                      placeholder={intl.get("productKeywords")}
                      value={onSearch}
                      status="warning"
                      size="large" />

                    <Input className="search_input" allowClear onChange={this.onSearchProductCode}
                      placeholder={intl.get("ProductCode")}
                      value={onSearchProductCode}
                      status="warning"
                      size="large" />
                    <div className="button-group">

                      <Button style={{ marginRight: "10px" }} onClick={this.onSearchProduct}
                        type="primary" size="large">
                        {intl.get("query")}

                      </Button>
                      <Button size="large" onClick={this.onSearchReset}>{intl.get("reset")}</Button>
                    </div>
                  </div>
                  <div className="sort-container">
                    <TreeSelect
                      treeData={treeData}
                      value={value}
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeDefaultExpandAll={false}
                      treeIcon={false} // 禁用默认图标
                      showSearch={false} // 根据需求开启搜索
                      treeNodeFilterProp="title"
                      fieldNames={{
                        label: 'title',
                        value: 'value',
                        children: 'children'
                      }}
                    />

                  </div>
                  <div className="sort-container">
                    {intl.get("sortBy")}: <Select
                      size="large"
                      defaultValue="1"
                      className="sort-select"
                      onChange={pageContext.handleChange}
                      options={[
                        {
                          value: '1',
                          label: intl.get("HightoLowSales"),
                        },
                        {
                          value: '2',
                          label: intl.get("LowtoHighSales"),
                        },
                        {
                          value: '3',
                          label: intl.get("LowtoHighPrice"),
                        },
                        {
                          value: '4',
                          label: intl.get("HightoLowPrice"),
                        },
                      ]}
                    /></div>
                </div>
                <div className="product-list-wrapper">

                  {tabskeys == "first" ?

                    <ProductListing
                      sort={
                        !pageContext.state.sort ? "asc" : pageContext.state.sort
                      }
                      onChildEvent={pageContext.handleChildEvent}
                      listShopCate={listShopCate}
                      tabskeys={tabskeys}
                      onRefresh={pageContext.onRefresh}
                      colSize={pageContext.state.layoutColumns}
                      propsState={pageContext.state}
                    />

                    :

                    <ProductListing
                      sort={
                        !pageContext.state.sort ? "asc" : pageContext.state.sort
                      }
                      onChildEvent={pageContext.handleChildEvent}
                      listShopCate={listShopCate}
                      tabskeys={tabskeys}
                      onRefresh={pageContext.onRefresh}
                      colSize={pageContext.state.layoutColumns}
                      cateCode={pageContext.state.cateCode}
                      propsState={pageContext.state}
                    />
                  }
                </div>
              </ConfigProvider>

            </div>
          </div>
        </section>

        {/*Section End*/}
      </div>
    );
  }
}

export default CollectionNoSideBar;

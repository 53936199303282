import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import "./product-list-item.scss";
import { getToken } from "@/storage/LocalParamers";
import intl from "react-intl-universal";

import { putSelectGoods, putJumpLoginPage } from "@/storage/SessionParamers";
const errorPhoto = require('@/images/errorPhoto.png')
class ProductListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stock: "InStock",
      quantity: 1,
      image: "",
      modal: false,
    };
  }

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = e => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  onOpenConfirmModal = () => {
    this.setState({ modal: true });
  };

  onCloseConfirmModal = () => {
    this.setState({ modal: false });
  };
  onAddToCart = () => {
    if (!getToken()) {
      this.onOpenConfirmModal();
    } else {
      this.props.onAddToCartClicked(this.state.quantity);
    }
  };

  windowOpen = (product) => {
    putSelectGoods(product.goodsCode)
    window.open(`${process.env.PUBLIC_URL}/no-sidebar/product/${product.keyId}`, '_blank')
  }
  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      onAddToCompareClicked
    } = this.props;
    const { open, modal } = this.state;
    return (
      <div className="product-box product_margin">
        <Link
          onClick={() => this.windowOpen(product)}
        >
          <div className="img-wrapper">
            <div className="front">
              <img
                src={
                  !product.mainPicture ? this.state.image : product.mainPicture
                }
                onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
                className="product_img_size img-fluid"
                alt=""
              />
            </div>
            {product.specialStatus === 1 ? <div className='product-list-item-red'>{intl.get('discount')}</div> : ""}

          </div>
          <div className="product_detail_sty">
            <strong className="goodsName-style">
              {product.goodsCode + " " + product.goodsName}
            </strong>
            {getToken() ? <h4 className="color-black">
              <span className="price-textStyle">${product.price} </span> <span></span> <span className="fontSize-14">起</span>

            </h4> : ""}

            {product.variants ? (
              <ul className="color-variant">
                {product.variants.map((vari, i) => {
                  return (
                    <li
                      className={vari.color}
                      key={i}
                      title={vari.color}
                      onClick={() => this.onClickHandle(vari.images)}
                    ></li>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </div>
        </Link>


        <Modal
          // ref={modalRef}
          open={modal}
          center={true}
          onClose={this.onCloseConfirmModal}
          classNames={{
            modal: "customModal"
          }}
        // initialFocusRef={modalRef}
        >
          <div className="confirmModal">
            <h3>{intl.get("loginrequiredtoaddtocart")}</h3>
            <button
              className="newBtn btn btn-secondary"
              onClick={this.onCloseConfirmModal}
            >
              {intl.get("cancel")}
            </button>
            <Link
              onClick={() => putJumpLoginPage(window.location.pathname)}
              to="/pages/login">
              <button
                className="newBtn btn btn-solid"
              // onClick={() => this.onClickLoginFn()}
              >
                {intl.get("recognise")}
              </button>
            </Link>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ProductListItem;

import React, { Component } from 'react'
import { Select, Col, Row, Input } from 'antd'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
export default class registerPage extends Component {
  render() {
    return (
      <div>
        <div className="login_title_sty">{intl.get("registrations")}</div>
        <div className="login_sty">
          <form className="theme-form">
            <Row gutter={12}>
              <Col span={24}>
                <div className="login_form_group">
                  <label htmlFor="email">{intl.get("nicknames")}</label>
                  <div>
                    <input
                      type="text"
                      className="fname_inputs"
                      id="fname"
                      placeholder={intl.get("enterNickname")}
                      required=""
                      value={this.props.fname}
                      onChange={e => this.props.onInputFnameChange(e)}
                    />
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div className="login_form_group">
                  <label htmlFor="email">{intl.get("loginPassword")}</label>
                  <div>
                    <input
                      type="password"
                      className="fname_inputs"
                      id="pssword"
                      placeholder={intl.get("loginPassword")}
                      required=""
                      onChange={e => this.props.onInputLoginPasswordChange(e)}
                    />
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div className="login_form_group">
                  <label htmlFor="phone">{intl.get("phone")}</label>
                  <div className="region_select">
                    <div className="region">
                      <Select
                        defaultValue="853"
                        bordered={false}
                        style={{ width: "100%" }}
                        onChange={this.props.onSelectCountryCode}
                      >
                        <Select.Option value="853">{intl.get("macao")}</Select.Option>
                        <Select.Option value="852">{intl.get("hongkong")}</Select.Option>
                        <Select.Option value="86">{intl.get("china")}</Select.Option>
                      </Select>
                    </div>
                    <input
                      className="phone_inputs"
                      id="mobilePhoneNumber"
                      placeholder={intl.get("phoneNumber")}
                      value={this.props.mobilePhoneNumber || ""}
                      onChange={e => this.props.onInputPhoneChange(e)}
                    />
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div className="login_form_group">
                  <label htmlFor="review">{intl.get("ConfirmPassword")}</label>
                  <div>
                    <input
                      type="password"
                      className="fname_inputs"
                      id="confirmPassword"
                      placeholder={intl.get("ConfirmPassword")}
                      required=""
                      onChange={e => this.props.onInputConfirmPasswordChange(e)}
                    />
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div className="login_form_group">
                  <label htmlFor="review">{intl.get("captcha")}</label>
                  <div className="captcha_sty">
                    <input
                      type="text"
                      className="captcha_inputs1"
                      id="verificationCode"
                      placeholder={intl.get("pleaseEnterTheCAPTCHA")}
                      required=""
                      value={this.props.verificationCode || ""}
                      onChange={e => this.props.onInputCaptchaChange(e)}
                    />
                    {this.props.loading ? (
                      <div className="captcha_btn_time">{this.props.yztime}</div>
                    ) : (
                      <div
                        onClick={this.props.smsSend}
                        className="captcha_btn"
                      >
                        {intl.get("SendCAPTCHA")}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col>
                <div
                  onClick={() => this.props.handleOnOK()}
                  className="create_account_btn"
                >
                  {intl.get("registrations")}
                </div>
              </Col>
            </Row>

            <div className="push"></div>

          </form>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import Slider from "react-slick";
import "../common/index.scss";
import { connect } from "react-redux";
import './no-sidebar.css'
// import custom Components

import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import { cartNumber, purchaseNow, addToWishlist } from "@/actions";
import ImageZoom from "./common/product/image-zoom";

import RelatedProducts from './common/RelatedProducts'
import AddingProductsExistingOrderModal from './common/product/adding-products-existing-order-modal.jsx'


import { LeftOutlined, RightOutlined, ExclamationCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Spin } from 'antd';
import {
  selectDetail,
  getInventory,
  selectRelevantGoods,
  addCar,
  getDetail,
  listCar,
  imgsList,
  listParam,
  myOrders,
  orderDetail,
  updateOrder,
  listShop,
  listRecmds,
  listUserGoods
} from "@/utils/user";
import {
  getToken,
  getCurCode
} from "@/storage/LocalParamers";
import { toast } from "react-toastify";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

import { getMainStoreKeyId, getSelectGoods, getOwnerKeyId } from "@/storage/SessionParamers";
const errorPhoto = require('@/images/errorPhoto.png')
const { confirm } = Modal;

class NoSideBar extends Component {
  constructor() {
    super();
    loadLocales('products')
    this.state = {
      nav1: null,
      nav2: null,
      propValuList: [],
      refresh: false,
      selectProductsIMG: [],
      presentimg: null,
      selectProductsIMGIndex: 0,
      pagination: {
        current: 1,
        pageSize: 5,
        pages: 0
      },
      myOrdersPagination: {
        current: 1,
        size: 10,
        ownerKeyId: getOwnerKeyId(),
        sendGoodsStatus: "0",
        payStatus: "0"
      },
      myOrdersList: false,
      listSelectRelevantGoods: [],
      selectDetailKeyId: false,
      listParamRes: false,
      listCars: [],
      addProdState: false,
      useAddress: 0,
      orderDetailLoading: false,
      orderDetailList: false,
      orderDetail: false,
      productInfo: false,
      getLading: false
    };
    this.elementRef = React.createRef();
    this.containerRef = React.createRef();
    this.getProductInfoFn = this.getProductInfoFn.bind(this)
    this.selectDetail = this.selectDetail.bind(this)
  }
  onRef = (ref) => {
    this.child = ref;
  }
  componentDidMount() {
    this.setState({
      // presentimg: this.props.item.mainPicture,
      nav1: this.slider1,
      nav2: this.slider2
    });

    const pathname = this.props.location.pathname

    const keyid = pathname.substring(pathname.lastIndexOf("\/") + 1, pathname.length)
    const goodsKeyId = this.props.item;
    this.getProductInfoFn(keyid)
    this.getListRecmds(keyid)


    this.setState({ selectDetailKeyId: keyid });
    this.getCarsList();
    this.getMyOrders()

    // 
  }
  getProductInfoFn = (keyid) => {
    const goodsKeyId = {
      goodsKeyId: keyid,
      mainStoreKeyId: getMainStoreKeyId()
    }
    this.setState({ getLading: true });
    listShop(goodsKeyId).then(res => {
      this.setState({ getLading: false });
      if (res && res.records.length > 0 && res.records[0].isSale != "0") {
        const productInfo = res.records[0]

        this.setState({
          presentimg: productInfo.mainPicture,
          productInfo: productInfo,
        }, () => {
          this.selectRelevantGoods(keyid);
          this.selectDetail(keyid)
        });
      }
    })

  }


  getListRecmds = (goodsKeyId) => {
    const param = {
      mainStoreKeyId: getMainStoreKeyId(),
      ownerGoodKeyId: goodsKeyId,
      ownerKeyId: getOwnerKeyId(),
    }
    listRecmds(param).then(res => {
      this.setState({ listRecmds: res })
    })
  }
  getMyOrders = () => {
    const { myOrdersPagination } = this.state;

    myOrders(myOrdersPagination).then(res => {
      if (res && res.responseCode === "00" && res.data.records.length > 0) {
        this.getOrderDetail(res.data.records[0])
        let newPagination = {
          current: res.data.current,
          size: res.data.size,
          total: res.data.total,
        }
        this.setState({ myOrdersList: res.data.records, myOrdersPagination: newPagination });
      }
    })
  }

  getCarsList = () => {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList) {
          this.setState({
            listCars: res.data.shopCarVOList
          });
        }
      }
    });
  }

  selectDetail(keyId) {
    let params = {
      goodsKeyId: keyId,
      mainStoreKeyId: getMainStoreKeyId()
    };
    selectDetail(params).then(res => {
      let skuKeyId = null;
      let newList = res.propValuList
      for (const propValuListValKey in res.propValuList) {
        for (const keys in res.propGoodsKeyIdList) {
          if (keys.includes(res.properties)) {
            skuKeyId = res.propGoodsKeyIdList[keys]
          }
          for (let val of newList[propValuListValKey]) {
            val.canChoose = true
            if (keys.includes(val.keyId)) {
              val.relatedProduct = true
            }
            if (!val.relatedProduct) {
              val.relatedProduct = false
            }
          }
        }
      }
      this.setState(
        {
          selectDetail: res,
          propValuList: newList
        },
        () => {
          this.child.detailInfo();
          if (skuKeyId) {
            this.getDetail(skuKeyId)
          }
          this.forceUpdate();
        }
      );
      this.getInventory(res);
    });
  }

  updateClickSelectCategory(val) {
    this.setState({ propValuList: val })
  }

  getInventory(res) {
    let properties = res.propGoodsKeyIdList;
    let listProperties = [];
    for (let key in properties) {
      listProperties.push(key);
    }
    let params = {
      produceKeyId: res.relationGoodKeyId,
      mainStoreKeyId: getMainStoreKeyId(),
      properties: listProperties.length == 0 ? res.properties : listProperties
    };
    getInventory(params).then(res => {
      if (res.responseCode == "00") {
        this.setState({
          inventoryData: res.data
          // stock: res.totalQty
        }, () => {
          this.child.inventoryDataUpdata()
        });
      }
    });
  }

  getlistParam = (skuKeyId) => {
    const param = {
      skuKeyId
    }
    listParam(param).then(res => {
      if (res && res.responseCode === "00") {

        if (res.data.length > 0) {
          this.setState({ listParamRes: res.data })
        }
      }
    })
  }

  getDetail = (value) => {
    this.getlistParam(value)

    const { selectDetail } = this.state;
    let params = {
      skuKeyId: value,
      // skuKeyId: selectDetail.propGoodsKeyIdList[value.keyId],
      mainStoreKeyId: getMainStoreKeyId()
    };
    getDetail(params).then(res => {
      if (res) {
        res.propGoodsKeyIdList = selectDetail.propGoodsKeyIdList;
        this.getInventory(res);
        this.setState({ selectProductsData: res, presentimg: res.mainPicture, });
        let params2 = {
          produceKeyId: res.relationGoodKeyId
        };
        imgsList(params2).then(res => {
          if (res && res.length > 0) {
            this.setState({ selectProductsIMG: res });
          } else {
            this.setState({ selectProductsIMG: [] });
          }
          this.setState({ selectProductsIMGIndex: 0 });
        })
      }
    });

  };
  selectRelevantGoods(keyId) {
    const { item } = this.props;
    const { pagination } = this.state;

    let params = {
      goodsKeyId: !keyId ? item.keyId : keyId,
      mainStoreKeyId: getMainStoreKeyId(),
      current: pagination.current,
      size: pagination.pageSize,
    };
    selectRelevantGoods(params).then(res => {
      if (res && res.records.length > 0) {
        this.offersRef.relatedProductsInfo(res.records);
      }
      let newPagination = {
        current: res.current,
        pageSize: res.size,
        pages: res.pages
      }
      this.setState(
        {
          pagination: newPagination,
          selectProductsData: null,
          listSelectRelevantGoods: res.records
        },
        () => this.forceUpdate()
      );
    });
  }

  handleChildEvent = ref => {
    // 将子组件的实例存到 this.childRef 中, 这样整个父组件就能拿到
    this.childRef = ref;
  };
  //按钮事件处理
  handleClick = keyId => {
    this.selectDetail(keyId);
    this.selectRelevantGoods(keyId);
  };

  addCar = (quantity, relationGoodKeyId) => {
    const { item } = this.props;
    const { selectProductsData, listCars, inventoryData } = this.state;
    let list = listCars.filter(item => item.skuKeyId == selectProductsData.keyId)
    if (list.length > 0) {
      const prodTotalQty = inventoryData.totalQty - list[0].quantity
      if (prodTotalQty < 1) {
        confirm({
          title: intl.get('QuantityCartExceedsStockLimit'),
          icon: <ExclamationCircleFilled />,
          content: intl.get('ConfirmAddCancelNotAdd'),
          onOk() {
            let params = {
              outMainStoreKeyId: getMainStoreKeyId(),
              ownerKeyId: item.ownerKeyId,
              quantity: quantity,
              relationGoodKeyId: selectProductsData.relationGoodKeyId,
              skuKeyId: selectProductsData.keyId
            };
            addCar(params).then(res => {
              if (res.responseCode == "00") {
                this.listCars();
                toast.success(intl.get("AddSuccessfully"));
              }
            });
          },
          onCancel() { return },
        });
      } else {
        let params = {
          outMainStoreKeyId: getMainStoreKeyId(),
          ownerKeyId: item.ownerKeyId,
          quantity: quantity,
          relationGoodKeyId: selectProductsData.relationGoodKeyId,
          skuKeyId: selectProductsData.keyId
        };
        addCar(params).then(res => {
          if (res.responseCode == "00") {
            this.listCars();
            toast.success(intl.get("AddSuccessfully"));
          }
        });
      }
      // else {

      //   this.child.cartModifyQty(prodTotalQty)
      //   return
      // }
    } else {
      let params = {
        outMainStoreKeyId: getMainStoreKeyId(),
        ownerKeyId: item.ownerKeyId,
        quantity: quantity,
        relationGoodKeyId: selectProductsData.relationGoodKeyId,
        skuKeyId: selectProductsData.keyId
      };
      addCar(params).then(res => {
        if (res.responseCode == "00") {
          this.listCars();
          toast.success(intl.get("AddSuccessfully"));
        }
      });
    }



  };
  listCars = () => {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList) {
          if (res.data.shopCarVOList.length > 0) {
            let shopCarNum = 0
            shopCarNum = res.data.shopCarVOList.length
            this.props.cartNumber(shopCarNum)
            this.setState({
              listCars: res.data.shopCarVOList
            });
          }
        } else {
          this.props.cartNumber(0)
        }

      }
    });
  }
  modifyPage = (value) => {
    const { pagination } = this.state;
    let current = pagination.current;
    if (pagination.pages !== 0) {
      if (pagination.pages === pagination.current) {
        current = value === "1" ? 1 : pagination.current - 1
      } else if (pagination.pages > pagination.current) {
        current = value === "1" ? (pagination.current + 1) : pagination.current - 1
      }
      let newPagination = {
        current,
        pageSize: pagination.pageSize,
        pages: pagination.pages
      }
      this.setState({ pagination: newPagination }, () => this.selectRelevantGoods());
    }

  }
  newest = ref => {
    this.newestRef = ref;
  };
  offers = ref => {
    this.offersRef = ref;
  };

  // handleScroll = (e) => {
  //   const { selectProductsIMGIndex, selectProductsIMG } = this.state
  //   if (selectProductsIMG.length > 3 && selectProductsIMGIndex > 1) {
  //     if (this.elementRef.current) {

  //       this.elementRef.current.scrollLeft = 126 * selectProductsIMGIndex


  //     }
  //   }


  // }
  handleScroll = (event) => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollLeft += event.deltaY;

    }
  };

  onHandleScroll = (index) => {
    if (this.containerRef.current) {
      const elements = this.containerRef.current.children;
      const containerLeft = this.containerRef.current.getBoundingClientRect().left;
      const elementLeft = elements[index].getBoundingClientRect().left;
      const scrollPosition = elementLeft - containerLeft + this.containerRef.current.scrollLeft - this.containerRef.current.clientWidth / 2 + elements[index].clientWidth / 2;
      this.containerRef.current.scrollLeft = scrollPosition;
    }
  }

  openAddProdState = () => {
    this.setState({ addProdState: true })
  }

  onCloseAddProdModal = () => {
    this.setState({ addProdState: false, useAddress: 0 })
  }

  onCheckbox = (useAddress) => {
    // setUseAddress(checkedValue.target.value)
    this.setState({ useAddress })
  }
  loaderOrderList = (callBack) => {
    const { myOrdersPagination } = this.state
    let newmyOrdersPagination = myOrdersPagination
    if (typeof newmyOrdersPagination.size == "number") {
      newmyOrdersPagination.size += 10
    } else {
      newmyOrdersPagination.size = parseInt(newmyOrdersPagination.size) + 10
    }

    myOrders(newmyOrdersPagination).then(res => {
      if (res && res.responseCode === "00" && res.data.records.length > 0) {
        let newPagination = {
          current: res.data.current,
          size: res.data.size,
          total: res.data.total,
        }
        this.setState({ myOrdersList: res.data.records, myOrdersPagination: newPagination },
          () => { callBack(true) });
      } else {
        callBack(true)
      }
    })

  }


  getOrderDetail = (item) => {
    const param = { orderKeyId: item.keyId }
    this.setState({ orderDetailLoading: true });

    orderDetail(param).then(res => {
      if (res && res.responseCode === "00" && res.data) {
        this.setState({ orderDetail: { ...res.data, item }, orderDetailList: res.data.orderGoodsVOList });
      } else {
        this.setState({ orderDetail: false, orderDetailList: false });
      }
      this.setState({ orderDetailLoading: false });
    })
  }

  totalAmountFun = (tableList) => {
    const { orderDetail } = this.state
    let totalAmount = 0
    tableList.map(item =>
      item.specialStatus == 1 ? totalAmount += (item.specialPrice * item.orderQty) : totalAmount += (item.producePrice * item.orderQty)
    )
    if (orderDetail && orderDetail.clientDisc && typeof orderDetail.clientDisc == "number" && typeof totalAmount == "number") {
      totalAmount = totalAmount * (100 - orderDetail.clientDisc) * 0.01
    }
    let numb = Math.ceil(totalAmount * 100) / 100
    if (numb == 0) {
      return "0.00"
    } else {
      return numb.toFixed(2)
    }
  }
  totalDiscountAmountFun = (tableList) => {
    let totalAmount = 0
    tableList.map(item => item.specialStatus == 1 ? totalAmount += (item.specialPrice * item.orderQty) : "")
    if (totalAmount == 0) {
      return "0.00"
    } else {
      return totalAmount.toFixed(2)
    }

  }
  totalNormalAmountFun = (tableList) => {
    let totalAmount = 0
    tableList.map(item => item.specialStatus != 1 ? totalAmount += (item.producePrice * item.orderQty) : "")
    if (totalAmount == 0) {
      return "0.00"
    } else {
      return totalAmount.toFixed(2)
    }

  }



  totalNormalDiscAmtAmountFun = (newOrderGoodsVOList) => {
    const { orderDetail } = this.state
    let totalAmount = this.totalNormalAmountFun(newOrderGoodsVOList) * orderDetail.clientDisc * 0.01
    if (totalAmount == 0) {
      return "0.00"
    } else {
      return totalAmount.toFixed(2)
    }

  }
  addProdmodel = (item) => {
    const { orderDetail, selectProductsData } = this.state;
    let list = {
      // amtValue: 6.6,
      amtValue: selectProductsData.specialStatus == 1 ? selectProductsData.specialPrice : selectProductsData.price,
      detailRemark: "",
      produceKeyId: selectProductsData.relationGoodKeyId,
      imageUrl: selectProductsData.imageUrl,
      // keyId: selectProductsData.keyId,

      mainPicture: selectProductsData.mainPicture,
      orderQty: 1,
      produceName: selectProductsData.goodsName,
      producePrice: selectProductsData.price,
      specialPrice: selectProductsData.specialPrice,
      specialStatus: selectProductsData.specialStatus,
      tolalAmount: selectProductsData.specialStatus == 1 ? selectProductsData.specialPrice : selectProductsData.price,
      tolalSpecialPrice: selectProductsData.specialStatus == 1 ? selectProductsData.specialPrice : selectProductsData.price,
      // unitName :"支"
    }

    let params = { ...orderDetail }
    let newOrderGoodsVOList = [...params.orderGoodsVOList]
    newOrderGoodsVOList.push(list)

    params.amtValue = this.totalAmountFun(newOrderGoodsVOList)
    params.totalDiscountAmount = this.totalDiscountAmountFun(newOrderGoodsVOList)
    params.normalAmt = this.totalNormalAmountFun(newOrderGoodsVOList)
    params.normalDiscAmt = this.totalNormalDiscAmtAmountFun(newOrderGoodsVOList)
    params.orderGoodsVOList = newOrderGoodsVOList
    updateOrder(params).then(res => {
      if (res && res.responseCode == "00") {
        toast.success(res.responseMessage);
        this.getMyOrders();
      }
      this.onCloseAddProdModal()
      // this.setState({ modifyOrderDetailState: false, orderDetailModal: false });
    })
  }


  render() {
    const {
      symbol,
      item,
      addToCart,
      purchaseNow,
      addToWishlist
    } = this.props;
    var products = {
      fade: true
    };
    const {
      propValuList,
      inventoryData,
      selectDetail,
      listSelectRelevantGoods,
      selectProductsData
      , selectProductsIMG,
      presentimg,
      selectProductsIMGIndex,
      pagination,
      listParamRes,
      myOrdersList,
      addProdState,
      useAddress,
      myOrdersPagination,
      orderDetailLoading,
      orderDetailList,
      productInfo,
      listRecmds,
      getLading
    } = this.state;
    var productsnav = {
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      draggable: true,
      focusOnSelect: true
    };
    return (
      <div>
        <Breadcrumb title={intl.get('productCenter') + ' / ' + intl.get("ProductDetails")} />
        {/*Section Start*/}
        <div style={{ height: "40px" }}></div>
        <div className="page_loading">
          <Spin spinning={getLading} tip="Loading...">
            {productInfo ? (
              <section>
                <div className="collection-wrapper">
                  <div className="container">
                    <div className="sidebar_layout row">
                      <div className="row">
                        <div >
                          <Slider
                            {...products}
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            className="product-slick"
                            style={{ width: "380px" }}
                          >
                            <ImageZoom
                              image={presentimg}
                            />
                          </Slider>
                          <div className="picture_small" ref={this.containerRef} onWheel={e => this.handleScroll(e)}>
                            {selectProductsIMG.map((item, index) =>

                              <img
                                className={index === selectProductsIMGIndex ? "selectImg" : "noSelectImg"}
                                key={index}
                                src={item.url}
                                onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
                                alt=""
                                onClick={(e) => {
                                  this.onHandleScroll(index);
                                  this.setState({ presentimg: item.url, selectProductsIMGIndex: index })
                                }}
                              />
                            )}
                          </div>
                        </div>
                        {/* <SmallImages item={item} settings={productsnav} navOne={this.state.nav1} /> */}
                        <DetailsWithPrice
                          onRef={this.onRef}
                          selectDetail={!selectDetail ? [] : selectDetail}
                          symbol={symbol}
                          item={!selectProductsData ? productInfo : selectProductsData}
                          navOne={this.state.nav1}
                          getDetail={this.getDetail}
                          addToCartClicked={this.addCar}
                          BuynowClicked={purchaseNow}
                          addToWishlistClicked={addToWishlist}
                          inventoryData={inventoryData}
                          history={this.props.history}
                          updateClickSelectCategory={this.updateClickSelectCategory}
                          myOrdersList={myOrdersList}
                          openAddProdState={this.openAddProdState}
                          wishlistedProductsState={false}
                        />

                        <div>
                          <div className='sidebar_product_recommendation_title'>
                            {intl.get("RelatedRecommendations")}
                            <div>
                              <LeftOutlined
                                className={pagination.pages > 1 ? "" : "outlined_style"}
                                style={{ marginRight: "10px", cursor: pagination.pages > 1 ? "" : "not-allowed" }}
                                onClick={() => pagination.pages > 1 ? "" : ""} />
                              <RightOutlined
                                className={pagination.pages > 1 ? "" : "outlined_style"}
                                style={{ cursor: pagination.pages > 1 ? "" : "not-allowed" }}
                                onClick={() => pagination.pages > 1 ? "" : ""} />
                            </div>
                          </div>
                          <RelatedProducts
                            history={this.props.history}
                            sidebarPage="true"
                            recommendation={this.offers}>

                          </RelatedProducts>
                        </div>
                      </div>

                    </div>
                    <div style={{ marginBottom: "50px" }}>
                      <DetailsTopTabs
                        selectDetail={!selectDetail ? [] : selectDetail}
                        listParam={listParamRes}
                        listRecmds={listRecmds}
                        history={this.props.history}
                        symbol={symbol}
                      /></div>
                  </div>

                </div>

              </section>
            ) : (

              !getLading && productInfo == [] ?
                <div className="sale_off">
                  <ExclamationCircleOutlined className="text_grey" style={{ fontSize: "50px" }} />
                  <div className="text_grey fontSize-16">{intl.get("SaleOff")}</div>
                </div>

                : ""

            )
            }

            {addProdState ? <AddingProductsExistingOrderModal
              open={addProdState}
              myOrdersList={myOrdersList}
              onCloseAddProdModal={this.onCloseAddProdModal}
              onCheckbox={this.onCheckbox}
              useAddress={useAddress}
              loaderOrderList={this.loaderOrderList}
              myOrdersPagination={myOrdersPagination}
              orderDetailLoading={orderDetailLoading}
              orderDetailList={orderDetailList}
              getOrderDetail={this.getOrderDetail}
              addProdmodel={this.addProdmodel}
            /> : ""}
          </Spin>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    item: getSelectGoods(),
    symbol: getCurCode()
  };
};

export default connect(mapStateToProps, {
  cartNumber,
  purchaseNow,
  addToWishlist
})(NoSideBar);

export function browserLanguage() {
  var language = navigator.language || navigator.userLanguage;
  var langStr = String(language).toLocaleLowerCase();
  if (langStr.includes("zh")) {
    if (langStr.includes("cn")) {
      language = "zh-CN";
    }
    else {
      language = "zh-TW";
    }
  }
  else if (langStr.includes("en")) {
    language = "zh-TW";
  }
  else {
    language = "zh-TW";
  }
  /*
  if (language === "zh-HK" || language === "zh-TW") {
    language = "zh-TW";
  } else if (language === "zh" || language === "zh-CN" || language === "zh-cn") {
    language = "zh-CN";
  } else if (language !== "en-US") {
    language = "zh-TW";
  }
    */

  return language;
}

